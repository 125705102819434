<template>
  <section>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-tabs
          class="pa-0 ma-0 bottom-border-primary transparent-bg"
          v-model="application_details_form_selected_page"
          hide-slider
        >
          <v-tab
            :disabled="
              page == page_titles[1] && !applicationQuotesExists(application)
            "
            v-for="page in page_titles"
            :key="page"
          >
            {{ page }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card flat tile elevation-0 class="transparent-bg">
          <v-tabs-items v-model="application_details_form_selected_page">
            <v-tab-item :key="page_titles[0]">
              <slot name="application-form"></slot>
            </v-tab-item>
            <v-tab-item :key="page_titles[1]">
              <ApplicationQuotes
                v-if="application_details_form_selected_page == 1"
                :isApplicationLaunchAble.sync="isApplicationLaunchAble"
              ></ApplicationQuotes>
            </v-tab-item>
            <v-tab-item :key="page_titles[2]">
              <ApplicationNotes
                v-if="application_details_form_selected_page == 2"
                :isApplicationLaunchAble.sync="isApplicationLaunchAble"
              ></ApplicationNotes>
            </v-tab-item>
            <v-tab-item :key="page_titles[3]">
              <ApplicationApiCommunications
                v-if="application_details_form_selected_page == 3"
                :isApplicationLaunchAble.sync="isApplicationLaunchAble"
              ></ApplicationApiCommunications>
            </v-tab-item>
            <v-tab-item :key="page_titles[4]">
              <ApplicationNotesAndAttachments
                v-if="application_details_form_selected_page == 4"
                :isApplicationLaunchAble.sync="isApplicationLaunchAble"
              ></ApplicationNotesAndAttachments>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-tabs
          class="pa-0 ma-0 bottom-border-primary transparent-bg"
          v-model="application_details_form_selected_page"
          hide-slider
        >
          <v-tab
            :disabled="
              page == page_titles[1] && !applicationQuotesExists(application)
            "
            v-for="page in page_titles"
            :key="page"
          >
            {{ page }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import ApplicationQuotes from "@/components/tables/ApplicationQuotes";
import ApplicationNotes from "@/components/tables/ApplicationNotes";
import ApplicationNotesAndAttachments from "@/components/tables/ApplicationNotesAndAttachments";
import ApplicationApiCommunications from "@/components/tables/ApplicationApiCommunications";

import floodApplicationForm from "@/components/forms/application/flood";
import homeApplicationForm from "@/components/forms/application/home";

export default {
  props: {},
  components: {
    ApplicationQuotes,
    ApplicationNotes,
    ApplicationApiCommunications,
    ApplicationNotesAndAttachments,
    floodApplicationForm,
    homeApplicationForm,
  },
  data() {
    return {
      page_titles: [
        "Quote Profile",
        "Quote Results",
        "History",
        "Api Communications",
        "Notes & Attachments",
      ],
    };
  },
  computed: {
    ...mapState({ form_data: (state) => state.application.form_data }),
    ...mapState({ application: (state) => state.application.application }),
    application_details_form_selected_page: {
      set(val) {
        this.$store.state.application.application_details_form_selected_page =
          val;
      },
      get() {
        return this.$store.state.application
          .application_details_form_selected_page;
      },
    },
    isApplicationLaunchAble() {
      return (
        this.application &&
        this.application.payload &&
        this.application.payload.status != "launched" &&
        this.application.payload.status != "complete"
      );
    },
    formComponent() {
      if (this.application?.application_type) {
        return `${this.application?.application_type
          ?.toString()
          .toLowerCase()}ApplicationForm`;
      }
      return null;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    applicationQuotesExists(application) {
      return application?.quotes?.length;
    },
  },
};
</script>

<style lang="scss">
.text-field-left-border::before {
  position: absolute;
}
</style>
