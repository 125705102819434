<template>
	<v-card>
		<v-card-title style="background-color: #80808029">
			{{ is_request ? `Customer Request` : `Create Customer` }}
			<v-spacer></v-spacer>
			<v-btn v-if="!is_page" @click="$emit('close-dialog')" icon color="error">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-form @submit.prevent v-model="isValidForm" ref="form">
				<FormMaker
					:col="{ cols: 12 }"
					:model="form_data"
					:schema="form_structure"
				/>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn block color="primary" @click="submit()">Register</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import FormMaker from "@/components/FormMaker";
	import { required, validEmail, minLen } from "@/plugins/validationRules.js";

	export default {
		components: { FormMaker },
		props: {
			selectedModel: { required: false, default: null },
			is_page: { required: true, default: true },
			is_request: { required: true, default: true }
		},
		data: () => {
			return {
				isValidForm: false,
				form_data: {
					first_name: null,
					last_name: null,
					business_name: null,
					business_address: null,
					email: null,
					phone_number: null,
					password: null,
					description: null,
					is_email_verified: false,
					is_phone_verified: false
				}
			};
		},
		computed: {
			form_structure() {
				return {
					first_name: {
						type: "text",
						name: "first_name",
						label: "First Name",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: [required()]
					},
					last_name: {
						type: "text",
						name: "last_name",
						label: "Last Name",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: [required()]
					},
					email: {
						type: "text",
						name: "email",
						label: "Email",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: [required(), validEmail()]
					},
					phone_number: {
						type: "text",
						name: "phone_number",
						label: "Phone Number",
						...this.$helpers.formFieldsBasicLayout,
						mask: "(###) ###-####",
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: [required(), minLen(14, "10 Digits required.")]
					},
					password: {
						type: "password",
						label: "Password",
						...this.$helpers.formFieldsBasicLayout,
						rules: [required("Password is required."), minLen(8)]
					},
					business_name: {
						type: "text",
						name: "business_name",
						label: "Business Name",
						...this.$helpers.formFieldsBasicLayout,
						rules: [required()]
					},
					business_address: {
						type: "textarea",
						name: "business_address",
						label: "Business Address",
						...this.$helpers.formFieldsBasicLayout,
						rows: 3,
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: [required()]
					},
					description: {
						type: "textarea",
						name: "description",
						label: "Description",
						...this.$helpers.formFieldsBasicLayout,
						rows: 3,
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: []
					}
				};
			}
		},
		watch: {},
		mounted() {},
		methods: {
			submit() {
				var $this = this;
				this.$refs.form.validate();
				if (!this.isValidForm) return;

				let data = this.form_data;

				data["is_request"] = this.is_request;
				data["is_email_verified"] = this.is_request ? false : true;
				data["is_phone_verified"] = this.is_request ? false : true;

				this.post(`${$this.baseUrl}/register-customer`, data, true).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						$this.$emit("registered", data);
						if ($this.is_page) {
							$this.$router.replace({ name: "login" });
						}
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-custom-title {
		display: none;
	}
</style>
