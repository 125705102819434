<template>
	<v-flex xs12 sm6>
		<registerCustomer :is_request="true" :is_page="true" />
	</v-flex>
</template>

<script>
	import registerCustomer from "@/components/forms/registerCustomer";

	export default {
		components: { registerCustomer },
		data: () => {
			return {};
		},
		head: {
			title: function() {
				return {
					inner: "Register Customer Request"
				};
			}
		},
		computed: {},
		watch: {},
		mounted() {},
		methods: {}
	};
</script>

<style lang="scss">
</style>
