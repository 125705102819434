/* eslint-disable prettier/prettier */

const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
};
class Helper {
  constructor() {}
  formFieldsBasicLayout = {
    hidden: false,
    clearable: true,
    outlined: true,
    solo: true,
    // flat: true,
    hideDetails: false,
    class: "custom-style py-0 my-0 px-2",
    color: "primary",
    elevation: 0,
    dense: true,
    col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
  };

  weekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  timeHoursListAm = [
    "12pm",
    "11am",
    "10am",
    "09am",
    "08am",
    "07am",
    "06am",
    "05am",
    "04am",
    "03am",
    "02am",
    "01am",
  ];
  timeHoursListPm = [
    "12am",
    "11pm",
    "10pm",
    "09pm",
    "08pm",
    "07pm",
    "06pm",
    "05pm",
    "04pm",
    "03pm",
    "02pm",
    "01pm",
  ];

  getApplicationAlertDotColor(application) {
    // Red = lead missing critical data, could not auto-launch
    // Orange - could not send quote, email/phone number was invalid
    // Yellow = quotes sent via “time-out job” instead of “completed”.
    let alert = application?.notes?.find(
      (note) => note?.payload?.is_alert && note?.payload?.is_active
    );

    if (!alert) {
      return null;
    }

    if (
      ["auto_launch", "multiple_auto_launch"].includes(alert?.payload?.sent_by)
    ) {
      if (alert?.payload?.status == "Failed") {
        return "red";
      }
    }

    if (
      [
        "status_changed_to_complete_job_email",
        "auto_sended_quote_timeout",
        "auto_sended_quote_complete",
        "time_out_job_email",
        "time_out_job_sms",
      ].includes(alert?.payload?.sent_by)
    ) {
      if (alert?.payload?.status == "Failed") {
        return "orange";
      }
    }

    if (
      [
        "time_out_job_email",
        "time_out_job_sms",
        "auto_sended_quote_timeout",
      ].includes(alert?.payload?.sent_by)
    ) {
      return "yellow";
    }

    if (["application_quote_not_included"].includes(alert?.key)) {
      return "teal";
    }

    return "purple";
  }

  removeHtmlTags(str) {
    if (str === null || str === "") return null;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  isJson(str) {
    if (typeof str !== "string") return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === "[object Object]" || type === "[object Array]";
    } catch (err) {
      return false;
    }
  }

  formatCurrencyCtrl = ({ data, newVal, value, obj }) => {
    if (value && this.formatAsCurrency(value) != value.toString())
      return (data[obj.key] = this.formatAsCurrency(value));
    return value;
  };

  firstUpperCase = ({ data, value, obj }) => {
    if (value)
      return `${value.toString()[0].toUpperCase()}${value.toString().slice(1)}`;
    return value;
  };

  formatAsCurrency = (value) => {
    let newVal = 0;
    if (value) {
      // console.log(Number(value.toString().replace(/[^0-9\.]/g, "")));
      newVal = Number.parseInt(value.toString().replace(/[^0-9\.]/g, ""))
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
    return newVal;
  };

  parseNumbersFromString(str) {
    let newVal = str ? str.toString().replace(/ |a-z|A-Z|,| /gi, "") : null;
    return newVal;
  }

  log($v) {
    console.debug($v);
  }
  pluck(arr, key) {
    return arr.map((o) => o[key]);
  }

  arrayInsert(array, index) {
    const items = Array.prototype.slice.call(arguments, 2);

    return [].concat(array.slice(0, index), items, array.slice(index));
  }

  arraySum(arr) {
    return arr.reduce((total, num) => {
      return Number.parseFloat(total) + Number.parseFloat(num);
    }, 0);
  }

  array_unique_key_objs(arr, key = "id") {
    let unique = [...new Map(arr.map((item) => [item[key], item])).values()];
    return unique;
  }

  dataType64toFile(dataurl, filename = "NewFile") {
    //Convert base64 to file
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let newFile = new File([u8arr], filename, {
      type: mime,
    });
    return newFile;
  }

  toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  }

  jsonToFormData = (data) => {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
  };
  rendGenerator(length = 8) {
    var timestamp = new Date();
    var _getRandomInt = function (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    var randomStr = function () {
      var ts = timestamp.toString();
      var parts = ts.split("").reverse();
      var id = "";

      for (var i = 0; i < length; ++i) {
        var index = _getRandomInt(0, parts.length - 1);
        id += parts[index];
      }
      id.replace(" ", "");
      return id;
    };
    return randomStr();
  }
  randomNumber(min = 111111, max = 999999) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }
  webUrl() {
    if (window.location.origin.includes("localhost"))
      return "http://localhost:8000";
    return window.location.origin;
  }
  attachmentBase() {
    return this.webUrl() + "/agent.rocketflood.com/storage";
  }
  reportDownloadBase() {
    return this.webUrl() + "/agent.rocketflood.com/public";
  }
  baseUrl() {
    if (window.location.origin.includes("localhost"))
      return this.webUrl() + "/api";
    return this.webUrl() + "/agent.rocketflood.com/backend/public/api";
  }
  imageBase() {
    return this.webUrl() + "/agent.rocketflood.com/storage";
  }
  copyText(text = "Copied Text") {
    navigator.clipboard.writeText(text);
    // const el = document.createElement("textarea");
    // el.value = text;
    // document.body.appendChild(el);
    // el.select();
    // el.setSelectionRange(0, 99999); /* For mobile devices */

    // document.execCommand("copy");
    // document.body.removeChild(el);
    return text;
  }
  getObjectByKey(obj, is, value) {
    if (typeof is == "string")
      return this.getObjectByKey(obj, is.split("."), value);
    else if (is.length == 1 && value !== undefined) return (obj[is[0]] = value);
    else if (is.length == 0) return obj;
    else return this.getObjectByKey(obj[is[0]], is.slice(1), value);
  }
  testApiThrottle(numberOfRequest = 120) {
    // helpers.testApiThrottle();
    for (let i = 1; i <= numberOfRequest; i++) {
      fetch(this.baseUrl() + "/test/throttle")
        .then((val) => {
          console.log(`Request ${i}`, val);
        })
        .catch((error) => {
          console.log(`Request ${i}`, error);
        });
    }
  }
  getNumbersFromString(str = null) {
    if (str) return parseFloat(str?.toString()?.replace(/[^\d\.]*/g, ""));
    return 0;
  }

  downloadFromUrl(downloadUrl) {
    var link = document.createElement("a");
    link.href = downloadUrl;
    link.style = "visibility:hidden";
    link.target = "_blank";
    // link.type = "application/vnd.ms-excel";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default new Helper();
