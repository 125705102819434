<template>
  <div>
    <vue-editor
      use-custom-image-handler
      @image-added="handleImageAdded"
      :editorOptions="editorSettings"
      @ready="editorIsReady"
      @text-change="textChanged"
      id="text-editor"
      v-model="content"
      v-bind="$attrs"
    ></vue-editor>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import axios from "axios";
import ImageResize from "quill-image-resize-vue";
import { ImageDrop } from "quill-image-drop-module";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  components: {
    VueEditor,
  },
  props: ["value"],
  data() {
    return {
      editorContext: null,
      content: "<h1>Html For Editor</h1>",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
      },
    };
  },
  mounted() {
    this.content = this.value;
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) this.content = newVal;
    },
    content(newVal, oldVal) {
      if (newVal != oldVal) this.$emit("input", newVal);
    },
  },
  methods: {
    editorIsReady(editorContext) {
      // console.log(editorContext);
      if (editorContext) this.editorContext = editorContext;
    },
    textChanged(delta, oldDelta, source) {
      // console.log(delta, oldDelta, source);
      if (delta?.ops[1]?.insert == "{") {
        // console.log("showTypeHinting", true);
        this.$emit("showTypeHinting", true);
      } else {
        // console.log("showTypeHinting", true);
        this.$emit("showTypeHinting", false);
      }
    },
    addTextToCurrentCursorLocation(text = "testing") {
      var Editor = this.editorContext;
      var range = Editor.getSelection();
      var cursorLocation = range.index;
      var startIndex = cursorLocation;

      Editor.deleteText(startIndex, text.length);
      Editor.insertText(startIndex, text, Quill.sources.SILENT);
      Editor.setSelection(startIndex, Quill.sources.SILENT);
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);

      // console.log(formData);

      // axios({
      // 	url: this.base_url + "/file/fileupload",
      // 	method: "POST",
      // 	data: formData,
      // 	headers: { Authorization: "Bearer " + token.auth.userToken }
      // })
      // 	.then(result => {
      // 		let url = result.data.filePath; // Get url from response
      // 		Editor.insertEmbed(cursorLocation, "image", url);
      // 		resetUploader();
      // 	})
      // 	.catch(err => {
      // 		alert("yukleme basarisiz! " + err);
      // 	});
    },
  },
};
</script>

<style>
#text-editor {
  height: 250px;
  min-height: 200px;
}
</style>
