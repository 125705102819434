<template>
	<div class="d-flex flex-column btn-list-container transparent-2">
		<template v-for="(item, index) in items">
			<v-btn v-bind="$attrs" :key="index" @click="newValue = item">
				{{ item }}
			</v-btn>
		</template>
	</div>
</template>

<script>
	export default {
		components: {},
		props: ["value"],
		data() {
			return {
				newValue: null
			};
		},
		mounted() {
			this.newValue = this.value;
		},
		watch: {
			value(newVal, oldVal) {
				if (newVal != oldVal) this.newValue = newVal;
			},
			newValue(newVal, oldVal) {
				if (newVal != oldVal) this.$emit("input", newVal);
			}
		},
		computed: {
			items() {
				return this.$attrs.items || [];
			}
		},
		methods: {}
	};
</script>
<style lang="scss">
</style>