<template>
	<v-container fluid fill-height align-center justify-center>
		<router-view />
	</v-container>
</template>

<script>
	export default {
		components: {},
		data() {
			return {};
		},
		props: {},
		watch: {},
		created() {},
		methods: {}
	};
</script>
