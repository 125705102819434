import form_data from "@/plugins/data.json";
import http from "@/plugins/http";
import router from "@/router";
import moment from "moment";

const getGeneratedDwellingValue = (state) => {
  let dwelling = 0;
  let formData = state.form_data;
  let formType = formData.form_type;

  if (formData.state && formData.sqft && formType == "HO6") {
    dwelling = Number.parseFloat(50 * formData.sqft).toFixed(2);
  } else if (
    formData.state &&
    formData.sqft &&
    ["FL"].includes(formData.state)
  ) {
    dwelling = Number.parseFloat(150 * formData.sqft).toFixed(2);
  } else if (
    formData.state &&
    formData.sqft &&
    ["MA", "RI"].includes(formData.state)
  ) {
    dwelling = Number.parseFloat(200 * formData.sqft).toFixed(2);
  } else if (
    formData.state &&
    formData.sqft &&
    ["NH"].includes(formData.state)
  ) {
    dwelling = Number.parseFloat(175 * formData.sqft).toFixed(2);
  } else if (
    formData.state &&
    formData.sqft &&
    ["CT"].includes(formData.state)
  ) {
    dwelling = Number.parseFloat(185 * formData.sqft).toFixed(2);
  } else if (
    formData.state &&
    formData.sqft &&
    ["TX"].includes(formData.state)
  ) {
    dwelling = Number.parseFloat(125 * formData.sqft).toFixed(2);
  } else {
    dwelling = Number.parseFloat(150 * formData.sqft).toFixed(2);
  }

  if (dwelling > 10000000) {
    return 10000000;
  }

  // logic explanation
  // dwelling is equal to
  // HO6: 50 x sqft
  // FL: 150 x sqft
  // MA,RI: 200 x sqft
  // NH: 175 x sqft
  // CT: 185 x sqft
  // TX: 125 x sqft
  // ELSE: 150 x sqft

  return dwelling;
};

const fieldValueAlreadyNotUpdated = (field) => {
  return [null, "", " "].includes(field);
};

const mapApplicationDataToFormData = (applicationData = [], formData = {}) => {
  try {
    let mappedFormData = {};
    Object.keys(formData).forEach((formKey1) => {
      // console.log(
      //   `${formKey1}, ${applicationData[formKey1]}, ${formData[formKey1]}`
      // );
      if (![null, undefined].includes(applicationData[formKey1])) {
        if (
          [
            "is_cross_sale",
            "is_prior_address",
            "currently_insured",
            "immediate_coverage",
          ].includes(formKey1)
        ) {
          mappedFormData[formKey1] = !!applicationData[formKey1];
        } else {
          mappedFormData[formKey1] = applicationData[formKey1];
        }
      } else {
        mappedFormData[formKey1] = formData[formKey1];
      }
    });
    // console.log(mappedFormData);
    return mappedFormData;
  } catch (e) {
    console.log(e);
    return {};
  }
};

const application = {
  namespaced: true,
  state: () => ({
    form_data: form_data,
    application: {},
    agent_applications_list: [],
    application_source_list: [],
    agent_applications_list_pagination: {
      per_page: 25,
      current_page: 1,
      next_page_url: null,
      path: null,
      prev_page_url: null,
      from: null,
      last_page: null,
      last_page: null,
      total: null,
    },
    application_details_form_selected_page: 0,
    communication_types: [
      {
        text: "Email",
        value: "email",
      },
      {
        text: "SMS / MMS",
        value: "sms",
      },
    ],
    application_types: [
      // {
      //   text: "Quick Quote",
      //   value: "quick_quote",
      // },
      {
        text: "Home",
        value: "home",
      },
      {
        text: "Flood",
        value: "flood",
      },
      {
        text: "Life",
        value: "life",
      },
      {
        text: "Auto",
        value: "auto",
      },
      {
        text: "Pet",
        value: "pet",
      },
    ],
    application_data_providers: [
      {
        text: "Attom",
        value: "Attom",
      },
      {
        text: "NationalFlood",
        value: "NationalFlood",
      },
      {
        text: "SmartyStreet",
        value: "SmartyStreet",
      },
    ],
  }),
  mutations: {
    setFormData(state, value) {
      state.form_data = value;
    },
    setApplication(state, value) {
      state.application = value;
    },
  },
  getters: {
    form_data(state) {
      state.form_data;
    },
    application(state) {
      state.application;
    },
    agent_applications_list(state) {
      state.agent_applications_list;
    },
    application_source_list(state) {
      state.application_source_list;
    },
    agent_applications_list_pagination(state) {
      state.agent_applications_list_pagination;
    },
    application_details_form_selected_page(state) {
      state.application_details_form_selected_page;
    },
    communication_types(state) {
      state.communication_types;
    },
    application_types(state) {
      state.application_types;
    },
    application_data_providers(state) {
      state.application_data_providers;
    },
    getGeneratedDwellingValue(state) {
      return getGeneratedDwellingValue(state);
    },
  },
  actions: {
    async getApplicationSourceNameList({ state, rootState }) {
      let data = {
        session_key: rootState.auth.session_key,
      };
      return await http
        .post(
          helpers.baseUrl() + "/agent-portal/get-application-source-name-list",
          data
        )
        .then(
          (response) => {
            if (response.data.code == 200)
              state.application_source_list =
                response?.data?.application_source_name_list;
            return response.data;
          },
          (error) => {
            console.log(error);
            return error;
          }
        );
    },
    async getAgentApplicationsList({ state, rootState }, params) {
      const {
        agent_id = rootState?.selected_agent_id,
        application_source = [],
        filters = {},
      } = params;

      let queryString = new URLSearchParams({
        page: state.agent_applications_list_pagination.current_page,
        per_page: Number.parseInt(
          state.agent_applications_list_pagination.per_page
        ),
      }).toString();

      let data = {
        filters,
        agent_id,
        application_source,
        session_key: rootState.auth.session_key,
        customer_id: rootState.auth?.user?.customer?.id,
      };

      return await http
        .post(
          helpers.baseUrl() +
            `/agent-portal/get-agent-applications-list?${queryString}`,
          data
        )
        .then(
          (response) => {
            if (response.data.code == 200) {
              let applications = response.data.data.applications;
              let filteredApplications = applications.data;

              state.agent_applications_list = filteredApplications;
              state.agent_applications_list_pagination = {
                per_page: Number.parseInt(applications.per_page),
                current_page: Number.parseInt(applications.current_page),
                total: Number.parseInt(applications.total),
                next_page_url: applications.next_page_url,
                path: applications.path,
                prev_page_url: applications.prev_page_url,
                from: applications.from,
                last_page: applications.last_page,
                last_page: applications.last_page,
              };
            }

            return response.data;
          },
          (error) => {
            console.log(error);
            return error;
          }
        );
    },
    async getApplicationById(
      { state, commit, dispatch, rootState },
      application_id = null
    ) {
      if (application_id == null) router.back();
      let data = {
        session_key: rootState.auth.session_key,
        application_id,
      };

      const res = await http
        .post(helpers.baseUrl() + "/agent-portal/get-application-by-id", data)
        .then(
          async (response) => {
            if (response.data.code == 200) {
              let application = response.data.data.application;
              let client = application.client;
              let agent = application.agent;
              let propertyDetails = application.payload.useable_details;
              let formData = mapApplicationDataToFormData(
                propertyDetails,
                form_data
              );

              formData["api_address_data"] = {
                address1: formData.address1 || null,
                state: formData.state || null,
                city: formData.city || null,
                zip: formData.zip || null,
                county: formData.county || null,
                nfip_community_name: formData.nfip_community_name || null,
                elevation_datum: formData.elevation_datum || null,
              };

              if (fieldValueAlreadyNotUpdated(formData.application_type))
                formData.application_type = "flood";

              if (
                ["", null].includes(formData["address2"]) &&
                !["", null].includes(formData["condo_unit_number"])
              ) {
                formData["address2"] = formData["condo_unit_number"];
              }

              let formType =
                application.payload.status != "new"
                  ? formData.form_type
                  : formData.occupancy_type == "Single Family"
                  ? "HO3"
                  : formData.occupancy_type == "Condo Unit"
                  ? "HO6"
                  : formData.occupancy_type == "2-4 Family"
                  ? "DP3"
                  : "HO3";

              // console.log(formData, form_data);
              formData.searched_address = application.payload.address;
              formData.address = application.payload.address;
              formData.elevation_datum = formData?.elevation_datum?.includes(
                "29"
              )
                ? "NGVD 1929"
                : "NAVD 1988";

              formData.num_of_stories = propertyDetails.num_of_stories
                ? propertyDetails.num_of_stories.toString()
                : "1";

              formData.client_name_id = `${
                client.payload && client.payload.first_name
                  ? client.payload.first_name.toString().toUpperCase()
                  : ""
              } ${
                client.payload && client.payload.last_name
                  ? client.payload.last_name.toString().toUpperCase()
                  : ""
              }/${application.application_id}`;

              if (["new", "new_updated"].includes(application.payload.status)) {
                formData["plumbing_year"] = !fieldValueAlreadyNotUpdated(
                  formData.plumbing_year
                )
                  ? formData.plumbing_year
                  : Number.parseInt(
                      moment().subtract(10, "years").format("YYYY")
                    ) <= Number.parseInt(formData.year_build)
                  ? formData.year_build
                  : moment().subtract(9, "years").format("YYYY");

                formData["roof_year"] = !fieldValueAlreadyNotUpdated(
                  formData.roof_year
                )
                  ? formData.roof_year
                  : Number.parseInt(
                      moment().subtract(10, "years").format("YYYY")
                    ) <= Number.parseInt(formData.year_build)
                  ? formData.year_build
                  : moment().subtract(9, "years").format("YYYY");

                formData["electrical_year"] = !fieldValueAlreadyNotUpdated(
                  formData.electrical_year
                )
                  ? formData.electrical_year
                  : Number.parseInt(
                      moment().subtract(10, "years").format("YYYY")
                    ) <= Number.parseInt(formData.year_build)
                  ? formData.year_build
                  : moment().subtract(9, "years").format("YYYY");

                formData["hvac_year"] = !fieldValueAlreadyNotUpdated(
                  formData.hvac_year
                )
                  ? formData.hvac_year
                  : Number.parseInt(
                      moment().subtract(10, "years").format("YYYY")
                    ) <= Number.parseInt(formData.year_build)
                  ? formData.year_build
                  : moment().subtract(9, "years").format("YYYY");

                formData["hot_water_heater_year"] =
                  !fieldValueAlreadyNotUpdated(formData.hot_water_heater_year)
                    ? formData.hot_water_heater_year
                    : Number.parseInt(
                        moment().subtract(10, "years").format("YYYY")
                      ) <= Number.parseInt(formData.year_build)
                    ? formData.year_build
                    : moment().subtract(9, "years").format("YYYY");
              }

              if (["new"].includes(application.payload.status)) {
                if (["FL", "TX"].includes(formData.state)) {
                  formData.aop_deductible = "$2,500";
                  formData.hurricane_deductible = "2%";
                } else if (["MA", "RI", "NH"].includes(formData.state)) {
                  formData.aop_deductible = "$2,500";
                  formData.hurricane_deductible = "$2,500";
                }

                if (formData.state == "FL") {
                  formData.roof_covering =
                    Number.parseInt(formData.year_build) > 2001
                      ? ""
                      : "Meets FBC 2001";
                  formData.roof_deck_attachment =
                    Number.parseInt(formData.year_build) > 2001
                      ? ""
                      : "Level C";
                  formData.roof_wall_connection =
                    Number.parseInt(formData.year_build) > 2001
                      ? ""
                      : "Single Wraps";
                  formData.swr =
                    Number.parseInt(formData.year_build) > 2001 ? "" : "No";
                  formData.opening_protection =
                    Number.parseInt(formData.year_build) > 2001
                      ? ""
                      : "Unknown or none";
                } else {
                  formData.roof_covering = "";
                  formData.roof_deck_attachment = "";
                  formData.roof_wall_connection = "";
                  formData.swr = "";
                  formData.opening_protection = "";
                  formData.roof_shape = "Gable";
                }
              }

              formData.form_type = formType;

              commit("setFormData", formData);
              commit("setApplication", application);
            }

            return response.data;
          },
          (error) => {
            console.log(error);
          }
        );

      let dwelling = 0;
      await dispatch("generateDwellingValue").then((val) => (dwelling = val));
      state.form_data.dwelling = ![null, "", " "].includes(
        state.form_data.dwelling
      )
        ? state.form_data.dwelling
        : dwelling;

      return res;
    },
    generateDwellingValue({ state }) {
      return getGeneratedDwellingValue(state);
    },
    async refreshApplicationGender({ state, rootState }) {
      let data = {
        session_key: rootState.auth.session_key,
        name: state.form_data.first_name,
      };
      return await http
        .post(helpers.baseUrl() + "/agent-portal/get-gender-by-name", data)
        .then(
          (response) => {
            // console.log(response?.data?.data?.gender_details?.gender);
            if (response?.data?.code == 200) {
              if (
                !!response?.data?.data?.gender_details?.gender &&
                response?.data?.data?.gender_details?.accuracy > 50
              ) {
                state.form_data.gender = helpers.toTitleCase(
                  response?.data?.data?.gender_details?.gender
                );
                return response?.data?.data?.gender_details?.gender;
              }
            }
            return null;
          },
          (error) => {
            console.log(error);
            return error;
          }
        );
    },
    async refreshApplicationVehiclesInfo({ state, rootState }) {
      let data = {
        session_key: rootState.auth.session_key,
        first_name: state.form_data.first_name,
        last_name: state.form_data.last_name,
        address1: state.form_data.address1,
        city: state.form_data.city,
        state: state.form_data.state,
        zip: state.form_data.zip,
        date_of_birth: state.form_data.date_of_birth || "1970-01-01",
      };
      //console.log(data);
      return await http
        .post(
          helpers.baseUrl() +
            "/agent-portal/get-vehicles-details-from-confianza-api",
          data
        )
        .then(
          (response) => {
            // console.log(response?.data?.data);
            if (response?.data?.code == 200) {
              if (response?.data?.data?.vehicles_info.length > 0) {
                state.form_data.vehicles_info =
                  response?.data?.data?.vehicles_info;
                state.form_data.drivers_info =
                  response?.data?.data?.drivers_info;
                state.form_data.date_of_birth =
                  response?.data?.data?.date_of_birth ||
                  state.form_data.date_of_birth;

                return response?.data?.data;
              }
            }
            return null;
          },
          (error) => {
            console.log(error);
            return error;
          }
        );
    },
  },
};

export default application;
