<template>
  <div>
    <template v-for="(form, index) in address_detail_forms">
      <v-card-title
        :key="index + form.name + '-heading'"
        class="px-0 mx-0"
        style="border-bottom: solid black 1px"
      >
        <h3 class="font-weight-regular text-uppercase">
          <v-icon color="primary darken-1">{{ form.icon }}</v-icon>
          {{ form.title }}
        </h3>
        <v-spacer></v-spacer>
        <v-btn
          icon
          v-if="form.is_hideable"
          :color="form.is_showing ? '' : 'primary'"
          @click="
            form.is_showing
              ? (form.is_showing = false)
              : (form.is_showing = true)
          "
        >
          <v-icon>
            {{
              form.is_showing
                ? "mdi-arrow-up-drop-circle"
                : "mdi-arrow-down-drop-circle"
            }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        :key="index + form.name + '-form'"
        v-if="
          (form.is_hideable && form.is_showing) || form.is_hideable == false
        "
      >
        <div class="ma-2 mt-6">
          <FormMaker
            @errorsList="(val) => parent.updateErrorsList(index, val)"
            :col="{ cols: 12 }"
            :model="form_data"
            :schema="form_structure[form.name]"
          />
        </div>
      </v-card-text>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import FormMaker from "@/components/FormMaker";
import {
  required,
  validEmail,
  minLen,
  lessThenEqual,
  greaterThenEqual,
  numberRequired,
} from "@/plugins/validationRules.js";
import floodZones from "@/assets/floodZonesList";
import states from "@/assets/statesList";

const formatCurrencyCtrl = ({ data, newVal, value, obj }) => {
  if (value && formatAsCurrency(value) != value.toString())
    return (data[obj.key] = formatAsCurrency(value));
  return value;
};
const firstUpperCase = ({ data, value, obj }) => {
  if (value)
    return `${value.toString()[0].toUpperCase()}${value.toString().slice(1)}`;
  return value;
};
const formatAsCurrency = (value) => {
  let newVal = 0;
  if (value) {
    // console.log(Number(value.toString().replace(/[^0-9\.]/g, "")));
    newVal = Number.parseInt(value.toString().replace(/[^0-9\.]/g, ""))
      .toString()
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }
  return newVal;
};

export default {
  props: {
    application_id: {
      required: true,
    },
    parent: {
      required: true,
    },
  },
  components: {
    FormMaker,
  },
  data() {
    return {
      floodZones,
      states,
      errorsList: [],
      isValidForm: false,
      address_detail_forms: [],
    };
  },
  computed: {
    ...mapState({
      form_data: (state) => state.application.form_data,
      application: (state) => state.application.application,
    }),
    ...mapGetters("application", {
      dwellingLimit: "getGeneratedDwellingValue",
    }),
    application_details_form_selected_page: {
      set(val) {
        this.$store.state.application.application_details_form_selected_page =
          val;
      },
      get() {
        return this.$store.state.application
          .application_details_form_selected_page;
      },
    },
    isApplicationLaunchAble() {
      return (
        this.application &&
        this.application.payload &&
        this.application.payload.status != "launched" &&
        this.application.payload.status != "complete"
      );
    },
    showElevationCertificateFields() {
      return this.form_data.elevation_certificate == "Yes";
    },
    form_structure() {
      return {
        client_info: {
          owned_type: {
            type: "btn-toggle",
            name: "owned_type",
            // label: "Owned",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            class: "custom-style py-0 pt-4",
            color: "primary",
            class: "",
            elevation: 0,
            options: ["Individual Owned", "Business Owned"],
            // offset: { offset: "1" },
            spacer: this.form_data.owned_type != "Business Owned",
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          business_name: {
            hidden:
              this.form_data.owned_type == "Business Owned" ? false : true,
            type: "text",
            name: "business_name",
            label: "Business Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          first_name: {
            type: "text",
            name: "first_name",
            id: "first_name",
            label: "First Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [required()],
          },
          last_name: {
            type: "text",
            name: "last_name",
            label: "Last Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [required()],
          },
          phone: {
            type: "tel",
            name: "phone",
            label: "Phone",
            class: "custom-style show-message",
            messages:
              this.form_data.requester_phone &&
              this.form_data.phone == this.form_data.requester_phone
                ? ["This is realtor/mortgage broker's info."]
                : [],
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            mask: "(###) ###-####",
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [minLen(14, "10 Digits required.")],
          },
          email: {
            type: "email",
            name: "email",
            label: "Email Address",
            class: "custom-style show-message",
            messages:
              this.form_data.requester_email &&
              this.form_data.email == this.form_data.requester_email
                ? ["This is realtor/mortgage broker's info."]
                : [],
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: "auto",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [validEmail()],
          },
        },
        property_info: {
          flood_zone: {
            type: "select",
            name: "flood_zone",
            class: "custom-style",
            label: "Flood Zone",
            clearable: true,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: this.floodZones,
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules:
              this.form_data.application_source == "Agent Portal"
                ? [required()]
                : [],
          },
          elevation: {
            type: "special-info-tabs",
            name: "elevation",
            label: "",
            class: "custom-style mt-0 primary-color",
            elevation: 0,
            options: [
              {
                iconImage: require("@/assets/images/bfe-icon.png"),
                name: "BFE",
                value: Number.parseFloat(this.form_data.bfe_elevation).toFixed(
                  2
                ),
              },
              {
                iconImage: require("@/assets/images/property-elevation.png"),
                name: "Property Elevation",
                value: Number.parseFloat(
                  this.form_data.property_elevation
                ).toFixed(2),
              },
              {
                iconImage: require("@/assets/images/elevation-difference.png"),
                name: "Elevation Difference",
                value: Number.parseFloat(
                  this.form_data.elevation_difference_computed
                ).toFixed(2),
              },
            ],
            // offset: { offset: "1" },
            dense: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [],
          },
          year_build: {
            type: "text",
            ext: "number",
            name: "year_build",
            typeInt: "year",
            class: "custom-style",
            label: "Year Built",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mask: "####",
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          sqft: {
            type: "text",
            // ext: "number",
            name: "sqft",
            class: "custom-style",
            label: "SQFT",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mask: "################",
            rules: [required()],
          },
          construction_type: {
            type: "autocomplete",
            name: "construction_type",
            class: "custom-style",
            label: "Construction",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            items: [
              { text: "Frame" },
              { text: "Masonry" },
              { text: "Masonry Veneer" },
              { text: "Brick Veneer" },
              { text: "Superior Non Combustible" },
              { text: "Steel" },
            ],
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          num_of_stories: {
            type: "autocomplete",
            name: "num_of_stories",
            class: "custom-style",
            label: "Number of Stories",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            items: ["1", "2", "3", "4"],
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          garage_present: {
            type: "btn-toggle",
            name: "garage_present",
            label: "Garage Present?",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            class: "custom-style primary",
            class: "mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            spacer: this.form_data.garage_present == "Yes" ? false : true,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          garage_attached: {
            hidden: this.form_data.garage_present == "Yes" ? false : true,
            type: "btn-toggle",
            name: "garage_attached",
            label: "Garage Attached?",
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          divider1: {
            type: "divider",
            name: "divider1",
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          },
          occupancy_type: {
            type: "btn-toggle",
            name: "occupancy_type",
            label: "Building Type", //"Building Occupancy Type",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1 px-0 body-2",
            elevation: 0,
            options: [
              "Single Family",
              "2-4 Family",
              "Condo Unit",
              "Townhouse/Rowhouse",
            ],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          building_use: {
            type: "btn-toggle",
            name: "building_use",
            label: "Building Use",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1 body-2",
            elevation: 0,
            options: [
              "Primary Home",
              "Secondary",
              "Rental(Own)",
              "Rental(Rent)",
            ],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          condo_unit_number: {
            hidden:
              this.form_data.occupancy_type == "Condo Unit" ? false : true,
            type: "text",
            name: "condo_unit_number",
            class: "custom-style",
            label: "Condo Unit Number?",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          condo_floor: {
            hidden:
              this.form_data.occupancy_type == "Condo Unit" ? false : true,
            messages:
              this.form_data.condo_floor && this.form_data.condo_floor > 2
                ? ["Please confirm accuracy."]
                : [],
            type: "text",
            ext: "number",
            name: "condo_floor",
            class: "custom-style  show-message",
            label: "Condo Floor?",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          foundation_type: {
            type: "btn-toggle",
            name: "foundation_type",
            label: "Foundation Type",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: [
              "Slab",
              "Crawlspace",
              "Stilts Pilings",
              "Garage",
              "Basement",
              "Subgrade Crawlspace",
              "Split Level",
            ],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            mandatory: false,
            rules: [required],
          },
          stilts_pilings: {
            hidden:
              this.form_data.foundation_type == "Stilts Pilings" ? false : true,
            type: "btn-toggle",
            name: "stilts_pilings",
            label: "Stilts / Pilings?",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: [
              "Open",
              "Solid Enclosure - Unfinished",
              "Solid Enclosure - Living Space",
            ],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            mandatory: false,
            rules: [required],
          },
          garage_finished: {
            hidden: this.form_data.foundation_type == "Garage" ? false : true,
            type: "btn-toggle",
            name: "garage_finished",
            label: "Garage Finished?",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            // offset: { offset: "1" },
            spacer: true,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          basement_type: {
            hidden: this.form_data.foundation_type == "Basement" ? false : true,
            type: "btn-toggle",
            name: "basement_type",
            label: "Basement?",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Full Basement", "Walkout Basement"],
            // offset: { offset: "1" },
            spacer: this.form_data.basement_type == null,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          basement_finished: {
            hidden:
              this.form_data.foundation_type == "Basement" &&
              ["Full Basement", "Walkout Basement"].includes(
                this.form_data.basement_type
              )
                ? false
                : true,

            type: "btn-toggle",
            name: "basement_finished",
            label: "Basement Finished?",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          stairs_leading_to_front_door: {
            hidden:
              (this.form_data.foundation_type == "Slab" &&
                this.form_data.occupancy_type == "Condo Unit" &&
                this.form_data.condo_floor == 1) ||
              (this.form_data.foundation_type == "Slab" &&
                this.form_data.occupancy_type != "Condo Unit")
                ? false
                : true,
            type: "text",
            name: "stairs_leading_to_front_door",
            ext: "number",
            class: "custom-style",
            label: "Stairs leading to front door?",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            spacer: true,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [numberRequired()],
          },
        },
        content_coverage: {
          building_limit: {
            type: "text",
            name: "building_limit",
            label: "Dwelling Limit",
            prefix: "$",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            toCtrl: formatCurrencyCtrl,
            // fromCtrl: formatCurrencyCtrl,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [
              required(),
              lessThenEqual(
                this.form_data.building_replacement_cost,
                "Dwelling LIMIT CANNOT EXCEED BUILDING REPLACEMENT COST."
              ),
            ],
          },
          contents_limit: {
            type: "text",
            name: "contents_limit",
            label: "Contents Limit",
            prefix: "$",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            toCtrl: formatCurrencyCtrl,
            fromCtrl: formatCurrencyCtrl,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          building_replacement_cost: {
            type: "text",
            name: "building_replacement_cost",
            label: "Building Replacement Cost*",
            prefix: "$",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            toCtrl: formatCurrencyCtrl,
            fromCtrl: formatCurrencyCtrl,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [
              // !!this.dwellingLimit
              //   ? greaterThenEqual(
              //       this.dwellingLimit,
              //       `Building replacement cost cannot be less than $${this.dwellingLimit}`
              //     )
              //   : () => {},
            ],
          },
          deductible: {
            type: "btn-toggle",
            name: "deductible",
            label: "Deductible",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: [
              "$1,000",
              // "$1,250",
              "$2,000",
              "$5,000",
              "$10,000",
              "$25,000",
            ],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            mandatory: false,
            rules: [required],
          },
        },
        optional_coverage: {
          replacement_cost_on_content: {
            type: "btn-toggle",
            name: "replacement_cost_on_content",
            label: "Replacement Cost On Contents?",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          temporary_living_expense: {
            type: "btn-toggle",
            name: "temporary_living_expense",
            label: "Temporary Living Expense?",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          other_structure: {
            type: "text",
            // ext: "number",
            name: "other_structure",
            label: "Other Structures",
            prefix: "$",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            toCtrl: formatCurrencyCtrl,
            fromCtrl: formatCurrencyCtrl,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          basement_content: {
            type: "text",
            // ext: "number",
            name: "basement_content",
            label: "Basement contents",
            prefix: "$",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            toCtrl: formatCurrencyCtrl,
            fromCtrl: formatCurrencyCtrl,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
        },
        elevation_certificate: {
          elevation_certificate: {
            type: "btn-toggle",
            name: "elevation_certificate",
            label: "Elevation Certificate?",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            spacer: true,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          nfip_community_name: {
            type: "text",
            name: "nfip_community_name",
            label: "NFIP Community Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          elevation_datum: {
            type: "btn-toggle",
            name: "elevation_datum",
            label: "Elevation Datum?",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["NGVD 1929", "NAVD 1988"],
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules:
              ["File Upload"].includes(this.form_data.application_source) &&
              this.form_data.elevation_certificate == "Yes"
                ? [required]
                : [],
          },
          lowest_floor_elevation: {
            hidden: !this.showElevationCertificateFields,
            type: "elevation-text-input",
            ext: "number",
            suffix: "ft.",
            name: "lowest_floor_elevation",
            label_special: "lowest_floor_elevation",
            rules: [],
          },
          next_highest_floor: {
            hidden: !this.showElevationCertificateFields,
            type: "elevation-text-input",
            ext: "number",
            suffix: "ft.",
            name: "next_highest_floor",
            label_special: "next_highest_floor",
            rules: [],
          },
          bottom_of_lower_horizontal_structure: {
            hidden: !this.showElevationCertificateFields,
            type: "elevation-text-input",
            ext: "number",
            suffix: "ft.",
            name: "bottom_of_lower_horizontal_structure",
            label_special: "bottom_of_lower_horizontal_structure",
            rules: [],
          },
          attached_garage_elevation: {
            hidden: !this.showElevationCertificateFields,
            type: "elevation-text-input",
            ext: "number",
            suffix: "ft.",
            name: "attached_garage_elevation",
            label_special: "attached_garage_elevation",
            rules: [],
          },
          lowest_elevation_of_machinery: {
            hidden: !this.showElevationCertificateFields,
            type: "elevation-text-input",
            ext: "number",
            suffix: "ft.",
            name: "lowest_elevation_of_machinery",
            label_special: "lowest_elevation_of_machinery or equipment",
            rules: [],
          },
          lowest_adjacent_grade: {
            hidden: !this.showElevationCertificateFields,
            type: "elevation-text-input",
            ext: "number",
            suffix: "ft.",
            name: "lowest_adjacent_grade",
            label_special: "lowest_adjacent_grade",
            rules: [],
          },
          highest_adjacent_grade: {
            hidden: !this.showElevationCertificateFields,
            type: "elevation-text-input",
            ext: "number",
            suffix: "ft.",
            name: "highest_adjacent_grade",
            label_special: "highest_adjacent_grade",
            rules: [],
          },
          lowest_adjacent_grade_at_lowest_elevation_of_deck_stairs: {
            hidden: !this.showElevationCertificateFields,
            type: "elevation-text-input",
            ext: "number",
            suffix: "ft.",
            name: "lowest_adjacent_grade_at_lowest_elevation_of_deck_stairs",
            label_special:
              "lowest_adjacent_grade_at_lowest_elevation_of_deck_stairs, including structural support",
            rules: [],
          },
        },
        requester_information: {
          requester_first_name: {
            type: "text",
            name: "requester_first_name",
            label: "First Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [],
          },
          requester_last_name: {
            type: "text",
            name: "requester_last_name",
            label: "Last Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [],
          },
          requester_email: {
            type: "email",
            name: "requester_email",
            class: "custom-style",
            label: "Email Address",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [validEmail()],
          },
          requester_phone: {
            type: "tel",
            name: "requester_phone",
            class: "custom-style",
            label: "Phone",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            mask: "(###) ###-####",
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [minLen(14, "10 Digits required.")],
          },
        },
      };
    },
  },
  watch: {
    "$store.state.application.form_data.sqft"(newVal, oldVal) {
      if (newVal != oldVal) {
        let sqft = parseInt(newVal);
        let occupancyTypeIsCondo =
          this.form_data["occupancy_type"] == "Condo Unit";

        let buildingLimit = sqft
          ? occupancyTypeIsCondo
            ? Math.min(50 * sqft, 250000)
            : Math.min(125 * sqft, 250000)
          : 250000;

        // logic explanation
        // buildingLimit is equal to
        // the minimum of 50 times sqft and 250000 if occupancyTypeIsCondo is true
        // the minimum of 125 times sqft and 250000 if occupancyTypeIsCondo is false

        let buildingReplacementCost = sqft
          ? occupancyTypeIsCondo
            ? 50 * sqft
            : 125 * sqft
          : 0;

        // logic explanation
        // buildingReplacementCost is equal to
        // sqft times 50 if occupancyTypeIsCondo is true 125 if occupancyTypeIsCondo is false

        this.form_data.building_limit = formatAsCurrency(buildingLimit);
        this.form_data.building_replacement_cost = formatAsCurrency(
          buildingReplacementCost
        );
      }
    },
    "$store.state.application.form_data.condo_unit_number"(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        let condoUnit = newVal.toString();
        let condoUnitDigitLength = condoUnit.length;

        this.form_data.condo_floor =
          condoUnitDigitLength > 2
            ? condoUnitDigitLength != 3
              ? condoUnitDigitLength != 4
                ? null
                : condoUnit.substring(0, 2)
              : condoUnit.substring(0, 1)
            : 1;
      }
    },
    "$store.state.application.form_data": {
      handler: function (val) {
        if (val && this.application && this.application.client) {
          // this.$refs.form.validate();
          let client = this.application.client;
          let firstName = val.first_name;
          let lastName = val.last_name;

          this.form_data.client_name_id = `${
            firstName ? firstName.toString().toUpperCase() : ""
          } ${lastName ? lastName.toString().toUpperCase() : ""}/${
            this.application.application_id
          }`;
          this.generateFoundationTypeComputed();
          this.generateStairsLeadingToFrontDoorComputed();
          this.generateElevationDifference();
        }
      },
      deep: true,
    },
  },
  created() {
    this.setAddressDetailFormsSections();
    // this.form_data.searched_address
    // 	? null
    // 	: this.$router.push({ name: "agent-portal" });
    // this.form_data.searched_address
    // 	? (this.$store.state.selected_agent_id = this.$store.state.application.agent.id)
    // 	: null;
  },
  mounted() {},
  methods: {
    setAddressDetailFormsSections() {
      this.address_detail_forms = [
        {
          title: "Client Information",
          icon: "mdi-account-circle-outline",
          name: "client_info",
          is_hideable: false,
        },
        {
          title: "Property Information",
          icon: "mdi-form-dropdown",
          name: "property_info",
          is_hideable: false,
        },
        {
          title: "Coverage",
          icon: "mdi-umbrella-outline",
          name: "content_coverage",
          is_hideable: false,
        },
        {
          title: "Optional Coverages",
          icon: "mdi-text-box-check",
          name: "optional_coverage",
          is_hideable: true,
          is_showing: false,
        },
        {
          title: "Elevation Certificate",
          icon: "mdi-elevation-rise",
          name: "elevation_certificate",
          is_hideable: true,
          // is_showing: true
          is_showing: !(
            this.hideEmptyRequiredFields("nfip_community_name") &&
            this.hideEmptyRequiredFields("elevation_datum")
          ),
        },
        {
          title: "Requester Information",
          icon: "mdi-information-variant",
          name: "requester_information",
          is_hideable: true,
          is_showing: false,
          hide: true,
        },
      ];
    },
    hideEmptyRequiredFields(val) {
      if (this.form_data && this.form_data.api_address_data)
        return (
          this.form_data.api_address_data[val] &&
          this.form_data.api_address_data[val] != ""
        );
      return false;
    },
    generateFoundationTypeComputed() {
      if (["Stilts Pilings"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${this.form_data.foundation_type} - ${this.form_data.stilts_pilings}`;
      } else if (["Garage"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${
          this.form_data.foundation_type
        } ${this.mapYesNoToFinishedUnfinished(this.form_data.garage_finished)}`;
      } else if (["Basement"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${
          this.form_data.basement_type
        } - ${this.mapYesNoToFinishedUnfinished(
          this.form_data.basement_finished
        )}`;
      } else {
        this.form_data.foundation_type_computed =
          this.form_data.foundation_type;
      }
      // console.log(this.form_data.foundation_type_computed);
    },
    generateStairsLeadingToFrontDoorComputed() {
      let value = 0;
      if (["Crawlspace"].includes(this.form_data.foundation_type_computed)) {
        value = 3;
      } else if (
        [
          "Stilts Pilings - Open",
          "Stilts Pilings - Solid Enclosure - Unfinished",
          "Garage Unfinished",
        ].includes(this.form_data.foundation_type_computed)
      ) {
        value = 16;
      } else {
        value = this.form_data.stairs_leading_to_front_door;
      }
      this.form_data.stairs_leading_to_front_door_computed = value;
      // console.log(this.form_data.foundation_type_computed);
    },
    generateElevationDifference: _.debounce(
      async function () {
        try {
          // 1. Slab + 0 but
          // Each “stair” = +.5
          // Example: 3 “stairs”: +1.5
          // 2. Crawlspace:   +1.5
          // 3. Stilts Pilings - Open: +8
          // 4. Stilts Pilings - Solid Enclosure - Unfinished: +8
          // 5. Stilts Pilings - Solid Enclosure - Living Space: +0
          // 6. Garage Unfinished +8
          // 7. Garage Finished +0
          // 8. Full Basement - Finished: -7
          // 9. Full Basement - Unfinished: -7
          // 10. Walkout Basement - Finished -7
          // 11. Walkout Basement - Unfinished -7
          // 12. Subgrade Crawlspace -1
          // 13. Split Level +0
          let diff = Number.parseFloat(this.form_data.elevation_difference);
          if (["Slab"].includes(this.form_data.foundation_type_computed)) {
            this.form_data.elevation_difference_computed = eval(
              diff +
                (this.form_data.stairs_leading_to_front_door
                  ? this.form_data.stairs_leading_to_front_door * 0.5
                  : 0)
            );
          } else if (
            ["Crawlspace"].includes(this.form_data.foundation_type_computed)
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff + 1.5
            ).toFixed(2);
          } else if (
            [
              "Full Basement - Finished",
              "Full Basement - Unfinished",
              "Walkout Basement - Finished",
              "Walkout Basement - Unfinished",
            ].includes(this.form_data.foundation_type_computed)
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff - 7
            ).toFixed(2);
          } else if (
            [
              "Garage Unfinished",
              "Stilts Pilings - Open",
              "Stilts Pilings - Solid Enclosure - Unfinished",
            ].includes(this.form_data.foundation_type_computed)
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff + 8
            ).toFixed(2);
          } else if (
            ["Subgrade Crawlspace"].includes(
              this.form_data.foundation_type_computed
            )
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff - 1
            ).toFixed(2);
          }
          // console.log(this.form_data.elevation_difference_computed);
        } catch (err) {
          console.log("error in generateElevationDifference code", err);
        }
      },
      50,
      {
        leading: true,
        trailing: false,
      }
    ),
    mapYesNoToFinishedUnfinished(val) {
      return val == "Yes" ? "Finished" : "Unfinished";
    },
  },
};
</script>

<style lang="scss">
.show-message {
  .v-text-field__details {
    display: block !important;
    &,
    * {
      color: #00b2ec !important;
    }
  }
}
</style>
