<template>
  <div>
    <template v-for="(form, index) in address_detail_forms">
      <v-card-title
        :key="index + form.name + '-heading'"
        class="px-0 mx-0"
        style="border-bottom: solid black 1px"
      >
        <h3 class="font-weight-regular text-uppercase">
          <v-icon color="primary darken-1" :class="form.icon_class">{{
            form.icon
          }}</v-icon>
          {{ form.title }}
        </h3>
        <v-spacer></v-spacer>
        <v-btn
          icon
          v-if="form.is_hideable"
          :color="form.is_showing ? '' : 'primary'"
          @click="
            form.is_showing
              ? (form.is_showing = false)
              : (form.is_showing = true)
          "
        >
          <v-icon>
            {{
              form.is_showing
                ? "mdi-arrow-up-drop-circle"
                : "mdi-arrow-down-drop-circle"
            }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        :key="index + form.name + '-form'"
        v-if="
          (form.is_hideable && form.is_showing) || form.is_hideable == false
        "
      >
        <div class="ma-2 mt-6">
          <FormMaker
            @errorsList="(val) => parent.updateErrorsList(index, val)"
            :col="{ cols: 12 }"
            :model="form_data"
            :schema="form_structure[form.name]"
            @click="handleFormInputClick"
          />
        </div>
      </v-card-text>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import FormMaker from "@/components/FormMaker";
import {
  required,
  validEmail,
  minLen,
  minNumber,
  maxNumber,
  notInInvalidValues,
  lessThenEqual,
  numberRequired,
} from "@/plugins/validationRules.js";
import floodZones from "@/assets/floodZonesList";
import states from "@/assets/statesList";
import months from "@/assets/monthsList";

const formatCurrencyCtrl = ({ data, newVal, value, obj }) => {
  if (value && formatAsCurrency(value) != value.toString())
    return (data[obj.key] = formatAsCurrency(value));
  return value;
};
const firstUpperCase = ({ data, value, obj }) => {
  if (value)
    return `${value.toString()[0].toUpperCase()}${value.toString().slice(1)}`;
  return value;
};
const formatAsCurrency = (value) => {
  let newVal = 0;
  if (value) {
    // console.log(Number(value.toString().replace(/[^0-9\.]/g, "")));
    newVal = Number.parseInt(value.toString().replace(/[^0-9\.]/g, ""))
      .toString()
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }
  return newVal;
};

export default {
  props: {
    application_id: {
      required: true,
    },
    parent: {
      required: true,
    },
  },
  components: {
    FormMaker,
  },
  data() {
    return {
      floodZones,
      states,
      months,
      errorsList: [],
      isValidForm: false,
      address_detail_forms: [],
    };
  },
  computed: {
    ...mapState({
      form_data: (state) => state.application.form_data,
      application: (state) => state.application.application,
    }),
    application_details_form_selected_page: {
      set(val) {
        this.$store.state.application.application_details_form_selected_page =
          val;
      },
      get() {
        return this.$store.state.application
          .application_details_form_selected_page;
      },
    },
    isApplicationLaunchAble() {
      return (
        this.application &&
        this.application.payload &&
        this.application.payload.status != "launched" &&
        this.application.payload.status != "complete"
      );
    },
    form_structure() {
      let occupancy_type_options =
        this.form_data.form_type == "HO3"
          ? ["Single Family", "2-4 Family", "Townhouse/Rowhouse"]
          : this.form_data.form_type == "HO6"
          ? ["Condo Unit", "Townhouse/Rowhouse"]
          : this.form_data.form_type == "DP3"
          ? ["Single Family", "2-4 Family", "Townhouse/Rowhouse"]
          : [];
      let building_use_options =
        this.form_data.form_type == "HO3"
          ? ["Primary Home", "Secondary"]
          : this.form_data.form_type == "HO6"
          ? ["Primary Home", "Secondary", "Tenant Occupied"]
          : [];
      return {
        client_info: {
          owned_type: {
            type: "btn-toggle",
            name: "owned_type",
            // label: "Owned",

            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            class: "custom-style py-0 pt-4",
            color: "primary",
            class: "",
            elevation: 0,
            options: ["Individual Owned", "Business Owned"],
            // offset: { offset: "1" },
            spacer: false,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          business_name: {
            hidden:
              this.form_data.owned_type == "Business Owned" ? false : true,
            type: "text",
            name: "business_name",
            label: "Business Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          date_of_birth: {
            type: "text",
            name: "date_of_birth",
            typeInt: "date",
            class: "custom-style",
            label: "Date of birth",
            clearable: false,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          first_name: {
            type: "text",
            name: "first_name",
            id: "first_name",
            label: "First Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [required()],
          },
          last_name: {
            type: "text",
            name: "last_name",
            label: "Last Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [required()],
          },
          phone: {
            type: "tel",
            name: "phone",
            class: "custom-style",
            label: "Phone",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            mask: "(###) ###-####",
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [minLen(14, "10 Digits required.")],
          },
          email: {
            type: "email",
            name: "email",
            class: "custom-style",
            label: "Email Address",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [validEmail()],
          },
          is_prior_address: {
            type: "switch",
            name: "immediate_coverage",
            label: "Prior Address",
            hideDetails: true,
            inset: true,
            color: "primary",
            dense: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            rules: [],
          },
          divider1: {
            hidden: !this.form_data.is_prior_address,
            type: "divider",
            name: "divider1",
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          },
          prior_address1: {
            hidden: !this.form_data.is_prior_address,
            type: "text",
            name: "prior_address1",
            label: "Address1",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          prior_address2: {
            hidden: !this.form_data.is_prior_address,
            type: "text",
            name: "prior_address2",
            label: "Address2",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          prior_city: {
            hidden: !this.form_data.is_prior_address,
            type: "text",
            name: "prior_city",
            label: "City",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          prior_state: {
            hidden: !this.form_data.is_prior_address,
            type: "select",
            name: "prior_state",
            class: "custom-style",
            label: "State",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.state,
            items: this.states,
            itemText: "name",
            itemValue: "alpha-2",
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          prior_zip: {
            hidden: !this.form_data.is_prior_address,
            type: "text",
            name: "prior_zip",
            label: "Zip",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          prior_county: {
            hidden: !this.form_data.is_prior_address,
            type: "text",
            name: "prior_county",
            label: "County",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          prior_last_month: {
            hidden: !this.form_data.is_prior_address,
            type: "select",
            name: "prior_last_month",
            class: "custom-style",
            label: "Last Month",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.state,
            items: this.months,
            itemText: "name",
            itemValue: "value",
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [required()],
          },
          prior_last_year: {
            hidden: !this.form_data.is_prior_address,
            type: "text",
            ext: "number",
            name: "prior_last_year",
            typeInt: "year",
            class: "custom-style",
            label: "Last Year",
            placeholder: "1947",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            mask: "####",
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
        },
        property_info: {
          year_build: {
            type: "text",
            ext: "number",
            name: "year_build",
            typeInt: "year",
            class: "custom-style",
            label: "Year Built",
            placeholder: "1947",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            mask: "####",
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          roof_year: {
            type: "text",
            ext: "number",
            name: "roof_year",
            typeInt: "year",
            class: "custom-style",
            label: "Roof Year",
            placeholder: this.form_data.year_build
              ? (this.form_data.year_build + 1).toString()
              : "1947",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            mask: "####",
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          sqft: {
            type: "text",
            // ext: "number",
            name: "sqft",
            class: "custom-style",
            label: "SQFT",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            mask: "################",
            rules: [required()],
          },
          num_of_stories: {
            type: "autocomplete",
            name: "num_of_stories",
            class: "custom-style",
            label: "Number of Stories",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            items: ["1", "2", "3", "4"],
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [required()],
          },
          construction_type: {
            type: "autocomplete",
            name: "construction_type",
            class: "custom-style",
            label: "Construction",
            placeholder: "Select Construction",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            items: [
              { text: "Frame" },
              { text: "Masonry" },
              { text: "Masonry Veneer" },
              { text: "Brick Veneer" },
              { text: "Superior Non Combustible" },
              { text: "Steel" },
            ],
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          flood_zone: {
            type: "select",
            name: "flood_zone",
            class: "custom-style",
            label: "Flood Zone",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: this.floodZones,
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [],
          },
          distance_to_coast: {
            type: "text",
            ext: "number",
            name: "distance_to_coast",
            class: "custom-style",
            label: "Distance to coast",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            appendOuterIcon: "mdi-map-marker",
            // "v-on:click-append-outer": `alert("test")`,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [required(), notInInvalidValues(["0"])],
          },
          // divider1: {
          // 	type: "divider",
          // 	name: "divider1",
          // 	col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
          // },
          occupancy_type: {
            type: "btn-toggle",
            name: "occupancy_type",
            label: "Building Type",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1 px-0 body-2",
            elevation: 0,
            options: occupancy_type_options,
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          building_use: {
            hidden: this.form_data.form_type == "DP3" ? true : false,
            type: "btn-toggle",
            name: "building_use",
            label: "Building Use",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1 body-2",
            elevation: 0,
            options: building_use_options,
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          no_of_families: {
            hidden:
              this.form_data.occupancy_type == "2-4 Family" ? false : true,
            type: "autocomplete",
            name: "no_of_families",
            class: "custom-style",
            label: "# of families",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            items: ["2", "3", "4"],
            dense: true,
            spacer: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          condo_unit_number: {
            hidden:
              this.form_data.occupancy_type == "Condo Unit" ? false : true,
            type: "text",
            name: "condo_unit_number",
            class: "custom-style",
            label: "Condo Unit Number?",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          condo_floor: {
            hidden:
              this.form_data.occupancy_type == "Condo Unit" ? false : true,
            type: "text",
            ext: "number",
            name: "condo_floor",
            class: "custom-style",
            label: "Condo Floor?",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          floors_in_building: {
            hidden:
              this.form_data.occupancy_type == "Condo Unit" ? false : true,
            type: "text",
            ext: "number",
            name: "floors_in_building",
            class: "custom-style",
            label: "Total floors in building",
            clearable: false,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required(), minNumber(1), maxNumber(150)],
          },
          foundation_type: {
            type: "btn-toggle",
            name: "foundation_type",
            label: "Foundation Type",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: [
              "Slab",
              "Crawlspace",
              "Stilts Pilings",
              "Garage",
              "Basement",
              "Subgrade Crawlspace",
              "Split Level",
            ],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            mandatory: false,
            rules: [required],
          },
          stilts_pilings: {
            hidden:
              this.form_data.foundation_type == "Stilts Pilings" ? false : true,
            type: "btn-toggle",
            name: "stilts_pilings",
            label: "Stilts / Pilings?",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: [
              "Open",
              "Solid Enclosure - Unfinished",
              "Solid Enclosure - Living Space",
            ],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            mandatory: false,
            rules: [required],
          },
          garage_finished: {
            hidden: this.form_data.foundation_type == "Garage" ? false : true,
            type: "btn-toggle",
            name: "garage_finished",
            label: "Garage Finished?",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            // offset: { offset: "1" },
            spacer: true,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          basement_type: {
            hidden: this.form_data.foundation_type == "Basement" ? false : true,
            type: "btn-toggle",
            name: "basement_type",
            label: "Basement?",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Full Basement", "Walkout Basement"],
            // offset: { offset: "1" },
            spacer: this.form_data.basement_type == null,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          basement_finished: {
            hidden:
              this.form_data.foundation_type == "Basement" &&
              ["Full Basement", "Walkout Basement"].includes(
                this.form_data.basement_type
              )
                ? false
                : true,

            type: "btn-toggle",
            name: "basement_finished",
            label: "Basement Finished?",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          stairs_leading_to_front_door: {
            hidden:
              (this.form_data.foundation_type == "Slab" &&
                this.form_data.occupancy_type == "Condo Unit" &&
                this.form_data.condo_floor == 1) ||
              (this.form_data.foundation_type == "Slab" &&
                this.form_data.occupancy_type != "Condo Unit")
                ? false
                : true,
            type: "text",
            name: "stairs_leading_to_front_door",
            typeInt: "year",
            class: "custom-style",
            label: "Stairs leading to front door?",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            spacer: true,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [numberRequired()],
          },
        },
        content_coverage: {
          form_type: {
            type: "select",
            name: "form_type",
            class: "custom-style",
            label: "Form Type",
            placeholder: "Select One",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: ["HO3", "HO6", "DP3"],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          dwelling: {
            type: "text",
            name: "dwelling",
            label: "Dwelling",
            prefix: "$",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            toCtrl: formatCurrencyCtrl,
            // fromCtrl: formatCurrencyCtrl,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required(), maxNumber(10000000)],
          },
          contents: {
            type: "text",
            name: "contents",
            label: "Contents",
            prefix: "$",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            toCtrl: formatCurrencyCtrl,
            // fromCtrl: formatCurrencyCtrl,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          other_structure: {
            type: "text",
            name: "other_structure",
            label: "Other Structure",
            prefix: "$",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            toCtrl: formatCurrencyCtrl,
            // fromCtrl: formatCurrencyCtrl,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [required()],
          },
          loss_of_use: {
            type: "text",
            name: "loss_of_use",
            label: "Loss Of Use",
            prefix: "$",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            toCtrl: formatCurrencyCtrl,
            // fromCtrl: formatCurrencyCtrl,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [required()],
          },
          medical_payments: {
            type: "select",
            name: "medical_payments",
            class: "custom-style",
            label: "Medical Payments",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            returnObject: false,
            color: "primary",
            elevation: 0,
            // value: "$1,000",
            items: ["$0", "$1,000", "$2,000", "$3,000", "$4,000", "$5,000"],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [],
          },
          liability: {
            type: "select",
            name: "liability",
            class: "custom-style",
            label: "Liability",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: [
              "$0",
              "$100,000",
              "$200,000",
              "$300,000",
              "$500,000",
              "$1,000,000",
            ],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [],
          },
          divider1: {
            type: "divider",
            name: "divider1",
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          },
          aop_deductible: {
            type: "btn-toggle",
            name: "aop_deductible",
            label: "AOP Deductible",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["$500", "$1,000", "$2,500", "$5,000", "$10,000"],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            mandatory: false,
            rules: [required],
          },
          hurricane_deductible: {
            type: "btn-toggle",
            name: "hurricane_deductible",
            label: "Hurricane Deductible",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["$1,000", "$2,500", "2%", "5%"],
            // offset: { offset: "1" },
            dense: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            mandatory: false,
            rules: [required],
          },
        },
        optional_coverage: {
          personal_property_replacement_cost: {
            type: "btn-toggle",
            name: "personal_property_replacement_cost",
            label: "Personal Property Replacement Cost",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
          ordinance_or_law: {
            type: "select",
            name: "ordinance_or_law",
            class: "custom-style",
            label: "Ordinance or Law",
            placeholder: "Select One",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            itemText: "text",
            itemValue: "value",
            // value: this.form_data.flood_zone,
            items: [
              { text: "No Coverage", value: "0" },
              { text: "10%", value: "10" },
              { text: "25%", value: "25" },
              { text: "50%", value: "50" },
            ],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          fungi_mold_coverage: {
            type: "select",
            name: "fungi_mold_coverage",
            class: "custom-style",
            label: "Fungi/Mold Coverage",
            placeholder: "Select One",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: ["$0", "$10,000", "$25,000", "$50,000"],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          water_backup: {
            type: "btn-toggle",
            name: "water_backup",
            label: "Water Backup",
            clearable: false,
            outlined: true,
            solo: true,
            text: true,
            hideDetails: false,
            color: "primary",
            class: "custom-style mt-1",
            elevation: 0,
            options: ["Yes", "No"],
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mandatory: false,
            rules: [required],
          },
        },
        _4_point: {
          roof_type: {
            type: "select",
            name: "roof_type",
            class: "custom-style",
            label: "Roof Type",
            placeholder: "Select One",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: [
              "Composite Shingle",
              "Architectural Shingles",
              "Wood Shingle",
              "Concrete/Clay Tile",
              "Metal",
              "Rolled Roofing",
              "Tar and Gravel",
              "Poured Concrete",
              "Superior Construction w/Poured Concrete",
              "Other",
            ],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [],
          },
          roof_year: {
            type: "text",
            ext: "number",
            name: "roof_year",
            typeInt: "year",
            class: "custom-style",
            label: "Roof Year",
            placeholder: this.form_data.year_build
              ? (this.form_data.year_build + 1).toString()
              : "1947",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            mask: "####",
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          plumbing_type: {
            type: "select",
            name: "plumbing_type",
            class: "custom-style text-field-left-border",
            label: "Plumbing Type",
            placeholder: "Select One",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: ["Copper", "PVC", "Galvanized", "Polybutylene", "PEX"],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            divider_vertical: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [],
          },
          plumbing_year: {
            type: "text",
            ext: "number",
            name: "plumbing_year",
            typeInt: "year",
            class: "custom-style",
            label: "Plumbing Year",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            mask: "####",
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          electrical_type: {
            type: "select",
            name: "electrical_type",
            class: "custom-style text-field-left-border",
            label: "Electrical Type",
            placeholder: "Select One",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: ["Circuit Breakers", "Fuses", "Knob and Tube"],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [],
          },
          electrical_year: {
            type: "text",
            ext: "number",
            name: "electrical_year",
            typeInt: "year",
            class: "custom-style",
            label: "Electrical Year",
            placeholder: this.$moment().subtract(9, "years").format("YYYY"),
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            mask: "####",
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          hvac_type: {
            type: "select",
            name: "hvac_type",
            class: "custom-style",
            label: "HVAC Type",
            placeholder: "Select One",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: [
              "None",
              "Electric",
              "Gas",
              "Propane",
              "Oil Tank located inside",
              "Oil Tank located outside above ground",
              "Oil Tank located outside underground",
            ],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            divider_vertical: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            rules: [],
          },
          hvac_year: {
            type: "text",
            ext: "number",
            name: "hvac_year",
            typeInt: "year",
            class: "custom-style",
            label: "HVAC Year",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 3, lg: 3, xl: 3 },
            mask: "####",
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          hot_water_heater_year: {
            type: "text",
            ext: "number",
            name: "hot_water_heater_year",
            typeInt: "year",
            class: "custom-style",
            label: "Hot Water Heater Year",
            clearable: true,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            mask: "####",
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
        },
        wind_mitigation: {
          roof_covering: {
            type: "select",
            name: "roof_covering",
            class: "custom-style",
            label: "Roof Covering",
            placeholder: "Select One",
            clearable: true,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: [
              "",
              "Non-FBC",
              "Meets FBC 2001",
              "Meets FBC 1994",
              "Reinforced Concrete Roof Deck",
            ],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          roof_deck_attachment: {
            type: "select",
            name: "roof_deck_attachment",
            class: "custom-style",
            label: "Roof Deck Attachment",
            placeholder: "Select One",
            clearable: true,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: [
              "",
              "Unknown",
              "Level A",
              "Level B",
              "Level C",
              "Reinforced Concrete Roof Deck",
              "Metal Deck - Type II or III",
              "Wood Deck - Type II or III",
            ],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          roof_wall_connection: {
            type: "select",
            name: "roof_wall_connection",
            class: "custom-style",
            label: "Roof Wall Connection",
            placeholder: "Select One",
            clearable: true,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: [
              "",
              "Unknown",
              "Clips",
              "Toe Nails",
              "Single Wraps",
              "Double Wraps",
              "Structural",
              "N/A",
            ],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          swr: {
            type: "select",
            name: "swr",
            class: "custom-style",
            label: "SWR",
            clearable: true,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: ["", "Yes", "No"],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          opening_protection: {
            type: "select",
            name: "opening_protection",
            class: "custom-style",
            label: "Opening Protection",
            placeholder: "Select One",
            clearable: true,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: ["", "A1", "A3", "Basic", "Unknown or none"],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          roof_shape: {
            type: "select",
            name: "roof_shape",
            class: "custom-style",
            label: "Roof Shape",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.flood_zone,
            items: ["", "Hip", "Gable", "Flat", "Other"],
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            spacer: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
        },
        requester_information: {
          requester_first_name: {
            type: "text",
            name: "requester_first_name",
            label: "First Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [],
          },
          requester_last_name: {
            type: "text",
            name: "requester_last_name",
            label: "Last Name",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style py-0 my-0",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            rules: [],
          },
          requester_email: {
            type: "email",
            name: "requester_email",
            class: "custom-style",
            label: "Email Address",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [validEmail()],
          },
          requester_phone: {
            type: "tel",
            name: "requester_phone",
            class: "custom-style",
            label: "Phone",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            mask: "(###) ###-####",
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [minLen(14, "10 Digits required.")],
          },
        },
      };
    },
  },
  watch: {
    "$store.state.application.form_data.occupancy_type"(newVal, oldVal) {
      if (newVal != oldVal) {
        if (
          ["Townhouse/Rowhouse", "Single Family", "Condo Unit"].includes(newVal)
        ) {
          this.form_data.no_of_families = "1";
        }
      }
    },
    "$store.state.application.form_data.form_type"(newVal, oldVal) {
      if (newVal != oldVal) {
        this.form_data.occupancy_type = null;
        this.form_data.building_use = null;

        this.$store
          .dispatch("application/generateDwellingValue")
          .then((val) => (this.form_data.dwelling = val));
      }
    },
    "$store.state.application.form_data.sqft"(newVal, oldVal) {
      if (newVal != oldVal) {
        let sqft = parseInt(newVal);
        let occupancyTypeIsCondo =
          this.form_data["occupancy_type"] == "Condo Unit";

        let buildingLimit = sqft
          ? occupancyTypeIsCondo
            ? Math.min(50 * sqft, 250000)
            : Math.min(125 * sqft, 250000)
          : 250000;

        let buildingReplacementCost = sqft
          ? occupancyTypeIsCondo
            ? 50 * sqft
            : 125 * sqft
          : 0;

        this.form_data.building_limit = formatAsCurrency(buildingLimit);
        this.form_data.building_replacement_cost = formatAsCurrency(
          buildingReplacementCost
        );

        this.$store
          .dispatch("application/generateDwellingValue")
          .then((val) => (this.form_data.dwelling = val));
      }
    },
    "$store.state.application.form_data.condo_unit_number"(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        let condoUnit = newVal.toString();
        let condoUnitDigitLength = condoUnit.length;

        this.form_data.condo_floor =
          condoUnitDigitLength > 2
            ? condoUnitDigitLength != 3
              ? condoUnitDigitLength != 4
                ? null
                : condoUnit.substring(0, 2)
              : condoUnit.substring(0, 1)
            : 1;
      }
    },
    "$store.state.application.form_data.dwelling"(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.form_data.contents =
          (Number.parseInt(newVal.toString().replace(/[^0-9\.]/g, "")) * 25) /
          100;

        this.form_data.other_structure =
          (Number.parseInt(newVal.toString().replace(/[^0-9\.]/g, "")) * 2) /
          100;

        this.form_data.loss_of_use =
          (Number.parseInt(newVal.toString().replace(/[^0-9\.]/g, "")) * 10) /
          100;
      }
    },
    "$store.state.application.form_data": {
      handler: function (val, oldVal) {
        if (val && this.application && this.application.client) {
          // this.$refs.form.validate();
          let client = this.application.client;
          let firstName = val.first_name;
          let lastName = val.last_name;

          this.form_data.client_name_id = `${
            firstName ? firstName.toString().toUpperCase() : ""
          } ${lastName ? lastName.toString().toUpperCase() : ""}/${
            this.application.application_id
          }`;

          if (
            val.occupancy_type != oldVal.occupancy_type &&
            val.occupancy_type == "Townhouse/Rowhouse"
          ) {
            this.form_data.townhouse = "Yes";
          } else {
            this.form_data.townhouse = "No";
          }

          // if (val.condo_unit_number != val.address2) {
          //   this.form_data.address2 = val.condo_unit_number;
          // }

          if (
            val.year_build != val.roof_year &&
            Number.parseInt(
              this.$moment().subtract(10, "years").format("YYYY")
            ) <= Number.parseInt(val.year_build)
          ) {
            this.form_data.roof_year = val.year_build;
          }

          this.generateFoundationTypeComputed();
          this.generateStairsLeadingToFrontDoorComputed();
          this.generateElevationDifference();
        }
      },
      deep: true,
    },
  },
  created() {
    this.setAddressDetailFormsSections();
    // this.form_data.searched_address
    // 	? null
    // 	: this.$router.push({ name: "agent-portal" });
    // this.form_data.searched_address
    // 	? (this.$store.state.selected_agent_id = this.$store.state.application.agent.id)
    // 	: null;
  },
  mounted() {},
  methods: {
    setAddressDetailFormsSections() {
      this.address_detail_forms = [
        {
          title: "Client Information",
          icon: "mdi-account-circle-outline",
          name: "client_info",
          is_hideable: false,
        },
        {
          title: "Property Information",
          icon: "mdi-form-dropdown",
          name: "property_info",
          is_hideable: false,
        },
        {
          title: "Coverage",
          icon: "mdi-umbrella-outline",
          name: "content_coverage",
          is_hideable: false,
        },
        {
          title: "Optional Coverages",
          icon: "mdi-text-box-check",
          name: "optional_coverage",
          is_hideable: true,
          is_showing: false,
          hide: true,
        },
        {
          title: "4 Point",
          icon: "mdi-gamepad-circle-outline",
          icon_class: "rotate_45",
          name: "_4_point",
          is_hideable: true,
          is_showing: false,
          hide: true,
        },
        {
          title: "Wind Mitigation",
          icon: "mdi-weather-windy",
          name: "wind_mitigation",
          is_hideable: true,
          is_showing: false,
          hide: true,
        },
        {
          title: "Requester Information",
          icon: "mdi-information-variant",
          name: "requester_information",
          is_hideable: true,
          is_showing: false,
          hide: true,
        },
      ];
    },
    generateFoundationTypeComputed() {
      if (["Stilts Pilings"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${this.form_data.foundation_type} - ${this.form_data.stilts_pilings}`;
      } else if (["Garage"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${
          this.form_data.foundation_type
        } ${this.mapYesNoToFinishedUnfinished(this.form_data.garage_finished)}`;
      } else if (["Basement"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${
          this.form_data.basement_type
        } - ${this.mapYesNoToFinishedUnfinished(
          this.form_data.basement_finished
        )}`;
      } else {
        this.form_data.foundation_type_computed =
          this.form_data.foundation_type;
      }
      // console.log(this.form_data.foundation_type_computed);
    },
    generateStairsLeadingToFrontDoorComputed() {
      let value = 0;
      if (["Crawlspace"].includes(this.form_data.foundation_type_computed)) {
        value = 3;
      } else if (
        [
          "Stilts Pilings - Open",
          "Stilts Pilings - Solid Enclosure - Unfinished",
          "Garage Unfinished",
        ].includes(this.form_data.foundation_type_computed)
      ) {
        value = 16;
      } else {
        value = this.form_data.stairs_leading_to_front_door;
      }
      this.form_data.stairs_leading_to_front_door_computed = value;
      // console.log(this.form_data.foundation_type_computed);
    },
    generateElevationDifference: _.debounce(
      async function () {
        try {
          // 1. Slab + 0 but
          // Each “stair” = +.5
          // Example: 3 “stairs”: +1.5
          // 2. Crawlspace:   +1.5
          // 3. Stilts Pilings - Open: +8
          // 4. Stilts Pilings - Solid Enclosure - Unfinished: +8
          // 5. Stilts Pilings - Solid Enclosure - Living Space: +0
          // 6. Garage Unfinished +8
          // 7. Garage Finished +0
          // 8. Full Basement - Finished: -7
          // 9. Full Basement - Unfinished: -7
          // 10. Walkout Basement - Finished -7
          // 11. Walkout Basement - Unfinished -7
          // 12. Subgrade Crawlspace -1
          // 13. Split Level +0
          let diff = Number.parseFloat(this.form_data.elevation_difference);
          if (["Slab"].includes(this.form_data.foundation_type_computed)) {
            this.form_data.elevation_difference_computed = eval(
              diff +
                (this.form_data.stairs_leading_to_front_door
                  ? this.form_data.stairs_leading_to_front_door * 0.5
                  : 0)
            );
          } else if (
            ["Crawlspace"].includes(this.form_data.foundation_type_computed)
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff + 1.5
            ).toFixed(2);
          } else if (
            [
              "Full Basement - Finished",
              "Full Basement - Unfinished",
              "Walkout Basement - Finished",
              "Walkout Basement - Unfinished",
            ].includes(this.form_data.foundation_type_computed)
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff - 7
            ).toFixed(2);
          } else if (
            [
              "Garage Unfinished",
              "Stilts Pilings - Open",
              "Stilts Pilings - Solid Enclosure - Unfinished",
            ].includes(this.form_data.foundation_type_computed)
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff + 8
            ).toFixed(2);
          } else if (
            ["Subgrade Crawlspace"].includes(
              this.form_data.foundation_type_computed
            )
          ) {
            this.form_data.elevation_difference_computed = eval(
              diff - 1
            ).toFixed(2);
          }
          // console.log(this.form_data.elevation_difference_computed);
        } catch (err) {
          console.log("error in generateElevationDifference code", err);
        }
      },
      50,
      {
        leading: true,
        trailing: false,
      }
    ),
    mapYesNoToFinishedUnfinished(val) {
      return val == "Yes" ? "Finished" : "Unfinished";
    },
    openPropertyInFloodFactor() {
      let address = this.application.payload.address;
      helpers.copyText(address);
      this.$notify({
        group: "msg",
        title: "Address Copied",
        text: address,
        type: "success",
      });
      let url = `http://quoterush.maprisk.com/`;
      window.open(url, "_blank");
    },
    openPropertyInGoogleMaps() {
      let address = this.application.payload.address;
      let url = `https://www.google.com/maps/place/${address}`;
      window.open(url, "_blank");
    },
    handleFormInputClick({
      on,
      id,
      key,
      value,
      params,
      obj,
      data,
      schema,
      parent,
      index,
      event,
    }) {
      if (
        on + "," + id + "," + key + "," + params.tag ==
        "click,form-base,distance_to_coast,append-outer"
      ) {
        this.openPropertyInGoogleMaps();
        // this.openPropertyInFloodFactor();
      }
    },
  },
};
</script>

<style lang="scss">
.text-field-left-border::before {
  position: absolute;
}
</style>
