import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
// import("roboto-fontface/css/roboto/sass/roboto-fontface.scss");
// import("@mdi/font/css/materialdesignicons.css");

import "./plugins/base";

import auth from "./plugins/auth";
import helpers from "./plugins/helpers";
import Mixins from "./plugins/Mixins";

import VueHead from "vue-head";
import Notifications from "vue-notification";

Vue.mixin(Mixins);

import Vidle from "v-idle";
Vue.use(Vidle);

import contenteditable from "vue-contenteditable";
Vue.use(contenteditable);

// import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
// Vue.use(VuetifyGoogleAutocomplete, {
//   apiKey: 'AIzaSyAx9V1bydwkNAYNR9QxLdQ9a9bIssU2Fo0', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
//   // version: '', // Optional
//   // language: '', // Optional
//   installComponents: true, // Optional (default: true) - false, if you want to locally install components
//   vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
// });

import NProgress from "vue-nprogress";
const nProgressOptions = {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: true, // Show progressbar when doing Vue.http, default: true
};
const nprogress = new NProgress();
Vue.use(NProgress, nProgressOptions);

Vue.use(Notifications);
Vue.use(VueHead);
Vue.use(require("vue-moment"));

window.auth = auth;
window.helpers = helpers;

Vue.prototype.$helpers = helpers;
Vue.config.productionTip = false;

import VueTextEditor from "@/components/special/VueTextEditor";
Vue.component("v-text-editor", VueTextEditor);
import VueTextCurrency from "@/components/special/VueTextCurrency";
Vue.component("v-text-currency", VueTextCurrency);
import ClickToCopyList from "@/components/special/ClickToCopyList";
Vue.component("v-click-to-copy-list", ClickToCopyList);
import VueFormBtnList from "@/components/special/VueFormBtnList";
Vue.component("v-form-btn-list", VueFormBtnList);
import VueElevationTextInput from "@/components/special/VueElevationTextInput.vue";
Vue.component("v-elevation-text-input", VueElevationTextInput);
import VueTextareaHtml from "@/components/special/VueTextareaHtml.vue";
Vue.component("v-textarea-html", VueTextareaHtml);

const init = () => {
  auth.initAuth();
  new Vue({
    nprogress,
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
};

document.addEventListener("deviceready", () => {
  init();
});

const isCordovaApp = typeof window.cordova !== "undefined";

if (!isCordovaApp) {
  document.dispatchEvent(new CustomEvent("deviceready", {}));
}
