<template>
  <div>
    <v-card-title>
      <v-text-field
        append-icon="mdi-magnify"
        v-model="search"
        label="Search"
        hide-details
        single-line
        clearable
      ></v-text-field>

      <v-btn icon class="mt-3" color="primary" @click="getApplicationQuotes()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <createApplicationCustomQuoteDialog
        @refresh="getApplicationQuotes()"
        :application="application"
      />

      <v-btn
        icon
        class="mt-3"
        :title="
          filters.hide_0_quotes
            ? 'Click To show 0 quotes'
            : 'Click To hide 0 quotes'
        "
        :color="filters.hide_0_quotes ? `red` : `green`"
        @click="
          filters.hide_0_quotes
            ? (filters.hide_0_quotes = false)
            : (filters.hide_0_quotes = true)
        "
      >
        <v-icon>{{ filters.hide_0_quotes ? `mdi-eye-off` : `mdi-eye` }}</v-icon>
      </v-btn>
      <v-btn
        v-if="
          application &&
          application.payload &&
          application.payload.status == 'complete'
        "
        @click="
          sendDownloadApplicationQuoteDialogShow(
            application,
            null,
            'send-email'
          )
        "
        color="primary"
        icon
      >
        <v-icon>mdi-email-arrow-right-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="
          application &&
          application.payload &&
          application.payload.status == 'complete'
        "
        @click="
          sendDownloadApplicationQuoteDialogShow(application, null, 'send-sms')
        "
        color="primary"
        icon
      >
        <v-icon>mdi-cellphone-message</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :sort-by="['payload.premium']"
        :sort-desc="[false]"
        :headers="headers"
        :items="quotes_filtered"
        :search="search"
        :items-per-page="-1"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 20, -1],
        }"
      >
        <template v-slot:item.payload.carrier_name="{ item }">
          <div class="text-capitalize" style="min-width: 150px">
            <a
              v-if="
                getSelectedQuoteCarrierLink(item, item.payload.carrier_name)
              "
              :href="
                getSelectedQuoteCarrierLink(item, item.payload.carrier_name)
              "
              @click="getOnClickEventForItem(item, item.payload.carrier_name)"
              target="_blank"
              rel="noopener noreferrer"
              style="color: black !important"
            >
              {{ selectedQuoteCarrierNameForUser(item) }}
            </a>
            <template v-else>
              <div
                @click="getOnClickEventForItem(item, item.payload.carrier_name)"
              >
                {{ selectedQuoteCarrierNameForUser(item) }}
              </div>
            </template>
          </div>
        </template>
        <template v-slot:item.payload.description="{ item }">
          <div v-html="getQuoteDescription(item)"></div>
        </template>
        <template v-slot:item.payload.premium="{ item }">
          <div style="min-width: 150px">
            {{
              item.payload.premium
                ? `$${Number.parseInt(
                    item.payload.premium.toString().replace(/[^0-9\.]/g, "")
                  )
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
                : `$0`
            }}
          </div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div class="text-capitalize" style="min-width: 150px">
            {{ item.updated_at }}
          </div>
        </template>
        <template v-slot:item.carrier_quote_number="{ item }">
          <div
            @click="
              !!item.carrier_quote_number
                ? $helpers.copyText(item.carrier_quote_number)
                : () => {}
            "
            class="text-capitalize"
            style="min-width: 80px; cursor: pointer"
          >
            <v-icon v-if="!!item.carrier_quote_number" small dense
              >mdi-content-copy</v-icon
            >&nbsp;{{ item.carrier_quote_number }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex" @click.stop>
            <v-btn
              @click="
                sendDownloadApplicationQuoteDialogShow(
                  application,
                  item,
                  'download'
                )
              "
              color="success"
              icon
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn
              @click="
                sendDownloadApplicationQuoteDialogShow(
                  application,
                  item,
                  'send-email'
                )
              "
              color="primary"
              icon
            >
              <v-icon>mdi-email-arrow-right-outline</v-icon>
            </v-btn>
            <v-btn
              @click="
                sendDownloadApplicationQuoteDialogShow(
                  application,
                  item,
                  'send-sms'
                )
              "
              color="primary"
              icon
            >
              <v-icon>mdi-cellphone-message</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <template v-if="sendDownloadApplicationQuoteDialog.dialogModel">
      <sendDownloadApplicationQuoteDialog
        v-bind="sendDownloadApplicationQuoteDialog"
        @closeDialog="sendDownloadApplicationQuoteDialogClose()"
      ></sendDownloadApplicationQuoteDialog>
    </template>
  </div>
</template>

<script>
import quoteCarriersUrlsList from "@/assets/quoteCarriersUrlsList.json";

import createApplicationCustomQuoteDialog from "@/components/dialogs/createApplicationCustomQuoteDialog.vue";
import sendDownloadApplicationQuoteDialog from "@/components/dialogs/sendDownloadApplicationQuoteDialog";

import { mapState } from "vuex";

export default {
  components: {
    sendDownloadApplicationQuoteDialog,
    createApplicationCustomQuoteDialog,
  },
  props: {},
  data() {
    return {
      sendDownloadApplicationQuoteDialog: {
        dialogModel: false,
        selectedModel: null,
        formTypeProp: "send",
        selectedApplication: null,
        selectedQuote: null,
      },
      quotes: [],
      filters: {
        hide_0_quotes: true,
        hiddenQuoteCarrierNames: ["Flood Risk"],
      },
      search: null,
      loadingIntervalId: null,
      quoteCarriersUrlsList: quoteCarriersUrlsList,
      headers: [
        {
          sortable: true,
          text: "Carrier Name",
          value: "payload.carrier_name",
          align: "left",
        },
        {
          sortable: true,
          text: "Description",
          value: "payload.description",
          align: "left",
        },
        {
          sortable: true,
          text: "Premium",
          value: "payload.premium",
          align: "left",
        },
        {
          sortable: true,
          text: "Quote Date",
          value: "updated_at",
          align: "left",
        },
        {
          sortable: true,
          text: "Quote Num.",
          value: "carrier_quote_number",
          align: "left",
        },
        {
          sortable: false,
          text: "Actions",
          value: "actions",
          align: "right",
        },
      ],
    };
  },
  created() {
    this.getApplicationQuotes();
  },
  computed: {
    ...mapState({
      application: (state) => state.application.application,
    }),
    quotes_filtered() {
      let quotes = this.quotes;
      quotes = quotes.filter((quote) => {
        if (this.filters.hide_0_quotes)
          return ![
            "",
            "null",
            null,
            0.0,
            0,
            "0",
            "0.00",
            "0.0",
            "$0",
            "$0.00",
            "$0.0",
            "$",
          ].includes(quote.payload.premium);
        return quote;
      });
      quotes = quotes.filter((quote) => {
        return !this.filters.hiddenQuoteCarrierNames.includes(
          quote.payload.carrier_name
        );
      });
      return quotes;
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getQuoteDescription(quote) {
      let description = quote?.description;

      if (
        [
          "Success",
          "1 Quotes found",
          "1 quotes found.",
          "Your Property is Eligible for this Product, with the Coverage(s) Selected.",
          "Quoted",
        ].includes(quote?.description)
      ) {
        description = "";
      }
      if (
        [
          "BAD DATA SUPPLIED TO API",
          "UNABLE TO RATE BASED ON INFORMATION GIVEN",
        ].includes(quote?.description)
      ) {
        description = "WRIGHT SYSTEM ERROR - PLEASE QUOTE MANUALLY";
      }
      if (
        ["THERE WAS A PROBLEM CREATING THE QUOTE"].includes(quote?.description)
      ) {
        description = "ERROR - PLEASE QUOTE MANUALLY";
      }
      if (["REFERRAL"].includes(quote?.description)) {
        description = "UW APPROVAL REQUIRED - BRIDGE TO REQUEST";
      }

      if (quote.carrier_name.includes("FF ") && quote.premium) {
        // description = `This quote excludes taxes and fees, please bridge to Flow and “select” quote for grand total.<br/>${description}`;
        description = `${description}`;
      }
      if (
        quote.carrier_name.includes("Johnson And Johnson ") &&
        quote.premium
      ) {
        description = `MUST VERIFY BUILDING & CONTENTS REPLACEMENT COST PRIOR TO BINDING. Contents replacement cost is a critical rating factor with JJ. Agent must confirm total value of contents & update quote prior to binding. E&O implications will be the responsibility of binding agent.<br/>${description}`;
      }

      return description;
    },
    getOnClickEventForItem(item, carrier_name) {
      if (carrier_name == "Beyond Flood") {
      }
    },
    async getApplicationQuotes() {
      this.$store
        .dispatch(
          "application/getApplicationById",
          this.$route.params.application_id
        )
        .then((res) => {
          if (res && res.code == 200) this.quotes = res.data.application.quotes;
          else this.$router.push({ name: "agent-portal" });
        });
    },
    async rerunBeyondFloodQuote() {
      let data = {
        application_id: this.application.id,
        quote_carriers_names: ["BeyondFlood"],
      };

      return await this.post(
        `${this.baseUrl}/agent-portal/rerun-application-local-quote-carriers`,
        data,
        false
      );
    },
    getSelectedQuoteCarrierLink(selectedQuote, carrierName) {
      if (this.currentUserAccesses?.display_carriers == "No") {
        return null;
      }

      if (selectedQuote?.payload?.quote_carrier_url) {
        return selectedQuote.payload.quote_carrier_url;
      }

      return this.quoteCarriersUrlsList?.find((carrier) => {
        return carrierName.includes(carrier.name);
      })?.url;
    },
    async sendDownloadApplicationQuoteDialogClose() {
      this.sendDownloadApplicationQuoteDialog.dialogModel = false;
      this.sendDownloadApplicationQuoteDialog.formTypeProp = null;
      this.sendDownloadApplicationQuoteDialog.selectedQuote = null;
      this.sendDownloadApplicationQuoteDialog.selectedApplication = null;
    },
    async sendDownloadApplicationQuoteDialogShow(
      application,
      quote = null,
      formTypeProp = "send-email"
    ) {
      this.sendDownloadApplicationQuoteDialog.dialogModel = true;
      this.sendDownloadApplicationQuoteDialog.formTypeProp = formTypeProp;
      this.sendDownloadApplicationQuoteDialog.selectedQuote = quote;
      this.sendDownloadApplicationQuoteDialog.selectedApplication = application;
    },
  },
};
</script>
