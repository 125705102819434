import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import {
  VRow,
  VCol,
  VTextField,
  VCard,
  VBtn,
  VTextarea,
  VTooltip,
  VCheckbox,
  VSelect,
  VSwitch,
  VAutocomplete,
  VCombobox,
  VMenu,
  VDatePicker,
  VTimePicker,
  VSlider,
  VIcon,
  VSpacer,
  VProgressCircular,
} from "vuetify/lib";
import {
  Ripple,
  Intersect,
  Touch,
  Resize,
  ClickOutside,
} from "vuetify/lib/directives";

Vue.use(Vuetify, {
  components: {
    VRow,
    VTooltip,
    VCol,
    VTextField,
    VCard,
    VBtn,
    VTextarea,
    VCheckbox,
    VSelect,
    VSwitch,
    VAutocomplete,
    VCombobox,
    VMenu,
    VDatePicker,
    VTimePicker,
    VSlider,
    VIcon,
    VSpacer,
    VProgressCircular,
  },
  directives: {
    Ripple,
    Intersect,
    Touch,
    Resize,
    ClickOutside,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3898EC",
        secondary: "#4e82ce",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
