import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import AuthLayout from "@/views/layouts/auth";
import DashboardLayout from "@/views/layouts/dashboard";
import AgentPortal from "@/views/layouts/agent-portal";
import TestMessages from "@/views/pages/agent-portal/test-messages";
import RegisterCustomerRequest from "@/views/pages/auth/RegisterCustomerRequest";
import Account from "@/views/pages/auth/Account";
import Login from "@/views/pages/auth/Login";
import Logout from "@/views/pages/auth/Logout";
import NoRoute404 from "@/views/errors/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "",
        component: Login,
        meta: {
          forGuest: true,
        },
      },
      {
        name: "register-customer-request",
        path: "register/customer/request",
        component: RegisterCustomerRequest,
        meta: {
          forGuest: true,
        },
      },
      {
        name: "logout",
        path: "logout",
        component: Logout,
        meta: {
          forGuest: false,
        },
      },
      {
        name: "account",
        path: "account",
        component: Account,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        name: "dashboard-home",
        path: "",
        component: () => import("@/views/pages/dashboard/Home"),
      },
      {
        name: "mga-management",
        path: "mga/management",
        component: () => import("@/views/pages/dashboard/MgaManagement"),
      },
      {
        name: "user-profile",
        path: "user/profile",
        component: () => import("@/views/pages/dashboard/UserProfile"),
      },
      {
        name: "settings",
        path: "settings",
        component: () => import("@/views/pages/dashboard/Settings"),
      },
      {
        name: "messaging-console",
        path: "messaging-console",
        component: () => import("@/views/pages/dashboard/MessagingConsole"),
      },
      {
        name: "quotes-portal-management",
        path: "quotes-portal/management",
        component: () =>
          import("@/views/pages/dashboard/QuotesPortal/Management"),
      },
      {
        name: "api-management",
        path: "api-management",
        component: () => import("@/views/pages/dashboard/ApiManagement"),
      },
      {
        name: "bulk-upload",
        path: "bulk-upload",
        component: () => import("@/views/pages/dashboard/BulkUpload"),
      },
    ],
  },
  {
    path: "/agent-portal",
    component: AgentPortal,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        name: "agent-portal",
        path: "dashboard",
        component: () => import("@/views/pages/agent-portal/index"),
      },
      {
        props: true,
        name: "application-details",
        path: "application-details/:application_id",
        component: () =>
          import("@/views/pages/agent-portal/application-details"),
      },
    ],
  },
  {
    path: "/client",
    component: AgentPortal,
    meta: {},
    children: [
      // {
      //   // props: true,
      //   // name: "client-application-details",
      //   // path: "application-details/:application_id",
      //   // component: () => import("@/views/pages/client/application-details"),
      // },
    ],
  },

  {
    path: "/test-messages",
    name: "Test Messages",
    component: TestMessages,
    meta: {
      title: "Test Messages",
    },
  },

  {
    path: "*",
    name: "404",
    component: NoRoute404,
    meta: {
      title: "Page Not Found 404",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some((x) => x.meta.requireAuth);
  const forGuest = to.matched.some((x) => x.meta.forGuest);

  if (store.getters["auth/authenticated"]) {
    store.dispatch("getGlobalSettings");
    store.dispatch("getAgentsList");
    store.dispatch("application/getApplicationSourceNameList");

    store.dispatch("auth/getUserSettings");
    store.dispatch("auth/getUserNotifications");
    store.dispatch("auth/getFreshUserData");
  }

  if (forGuest && store.getters["auth/authenticated"]) {
    console.log("Login Redirected");
    next({
      name: "account",
    });
  } else if (requireAuth && !store.getters["auth/authenticated"]) {
    console.log("Guest Redirected");
    store.dispatch("auth/logout");
    next({
      name: "login",
    });
  } else {
    next();
  }
});

export default router;
