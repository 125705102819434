<template>
  <div>
    <v-card-title class="transparent-2">
      <v-spacer></v-spacer>
      <v-btn icon color="primary" @click="getApplicationApiCommunications()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Api Name</th>
              <th class="text-left">Communications</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filtered_table_data" :key="index">
              <td cols="2">{{ index }}</td>
              <td>
                <v-row
                  v-for="(sub_item, sub_index) in item"
                  :key="sub_index"
                  align="center"
                  justify="center"
                >
                  <v-col cols="2">
                    {{ sub_index.toString().replace(/_/g, " ") }}
                  </v-col>
                  <v-col>
                    <div
                      class="d-flex flex-column"
                      v-for="(sub_subitem, sub_sub_index) in sub_item"
                      :key="sub_sub_index"
                    >
                      <JsonViewer
                        boxed
                        copyable
                        :value="getApiDataFormatAble(sub_subitem)"
                      ></JsonViewer>
                      <v-divider></v-divider>
                    </div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </div>
</template>

<script>
import JsonViewer from "vue-json-viewer";
import { mapState } from "vuex";

export default {
  components: { JsonViewer },
  props: {},
  data() {
    return {
      apiCommunications: [],
      search: null,
    };
  },
  created() {
    this.getApplicationApiCommunications();
  },
  computed: {
    ...mapState({
      application: (state) => state.application.application,
    }),
    filtered_table_data() {
      return this.apiCommunications;
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getApiDataFormatAble(data) {
      // console.log(data);
      let dataKeyName = data.payload.is_request ? "request" : "response";
      return {
        [dataKeyName]: {
          data: data.payload.data,
          created_at: data.created_at,
          updated_at: data.updated_at,
        },
      };
    },

    async getApplicationApiCommunications() {
      let data = {
        application_id: this.application.id,
      };
      return await this.post(
        `${this.baseUrl}/agent-portal/get-application-api-communication`,
        data,
        true
      ).then((data) => {
        if (data) {
          this.showMessageFromResponseDta(data);
          if (data.code == 200)
            this.apiCommunications = data.data.application_api_communications;
        }
        return data;
      });
    },
  },
};
</script>
