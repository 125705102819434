import Vue from "vue";
import Vuex from "vuex";
import http from "@/plugins/http";
import auth from "./auth";
import application from "./application";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { auth, application },
  state: {
    navigationDrawer: null,
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage: require(`@/assets/images/drawer-background.png`),
    global_settings: null,
    loading: false,
    specialLoading: false,
    selected_agent_id: null,
    agents_list: [],
  },
  mutations: {
    setGlobalSettings(state, value) {
      state.global_settings = value;
    },
    setNavigationDrawer(state, payload) {
      state.navigationDrawer = payload;
    },
    setSelectedAgentId(state, value) {
      state.selected_agent_id = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
  },
  getters: {
    getSelectedAgentId(state) {
      return state.selected_agent_id;
    },
    assignableAgentLists(state) {
      return state?.agents_list?.length
        ? state.agents_list.filter((user) => {
            return ["admin", "agent"].includes(user.role);
          })
        : [];
    },
    assignableAgentListsMapped(state) {
      return state?.agents_list?.length
        ? state.agents_list
            .filter((user) => {
              return ["admin", "agent"].includes(user.role);
            })
            .map((user) => {
              return {
                text: `${user.name} (${user.email})`,
                value: user.agent.id,
              };
            })
        : [];
    },
  },
  actions: {
    async getAgentsList({ state }) {
      let data = {
        session_key: state.auth.session_key,
      };
      return await http
        .post(helpers.baseUrl() + "/agent-portal/get-agents-list", data)
        .then(
          (response) => {
            if (response.data.code == 200)
              state.agents_list = response.data.data;
            return response.data;
          },
          (error) => {
            console.log(error);
            return error;
          }
        );
    },
    async getGlobalSettings({ state, commit }) {
      var data = {
        session_key: state.auth.session_key,
      };
      return await http
        .post(helpers.baseUrl() + "/get-global-settings", data)
        .then(
          (response) => {
            if (response.data.code == 200)
              commit("setGlobalSettings", response.data.data.global_settings);
            else {
              console.log("Error getting global settings", response.data);
            }
            return response;
          },
          (error) => {
            console.log("Error getting global settings", error);
            return error;
          }
        );
    },
    async updateGlobalSettings({ state, commit }, { payload }) {
      var data = {
        session_key: state.auth.session_key,
        payload,
      };
      return await http
        .post(helpers.baseUrl() + "/update-global-settings", data)
        .then(
          (response) => {
            if (response.data.code == 200)
              commit("setGlobalSettings", response.data.data.global_settings);
            else {
              console.log("Error updating setting", response.data);
            }
            return response;
          },
          (error) => {
            console.log("Error updating setting", error);
            return error;
          }
        );
    },
  },
});
