<template>
	<div
		class="d-flex flex-row align-items-center items-center"
		:class="
			['xs', 'sm', 'md'].includes($vuetify.breakpoint.name)
				? 'flex-column'
				: 'flex-row'
		"
	>
		<div class="flex-grow-1 pt-4 subtitle-2 text-capitalize">
			{{ label_special.replace(/_/g, " ") }}
		</div>
		<div class="flex-grow-1" style="max-width: 280px">
			<v-text-field
				style="margin-top: 0; padding-top: 0"
				v-model="cmpValue"
				v-bind:rules="rules"
				type="number"
				suffix="ft."
			></v-text-field>
		</div>
	</div>
</template>

<script>
	export default {
		model: { prop: "value", event: "input" },
		props: {
			value: {
				// type: String,
				type: [String, Number],
				default: "0",
			},
			label: {
				type: String,
				default: "",
			},
			label_special: {
				type: String,
				default: "",
			},
			placeholder: {
				type: String,
				default: undefined,
			},
			readonly: {
				type: Boolean,
				default: false,
			},
			dense: {
				type: Boolean,
				default: false,
			},
			error: {
				type: Boolean,
				default: false,
			},
			hideDetails: {
				type: [Boolean, String],
				default: false,
			},
			errorMessages: {
				type: [Array, String],
				default: () => [],
			},
			rules: {
				type: [Array, String],
				default: () => [],
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			outlined: {
				type: Boolean,
				default: false,
			},
			clearable: {
				type: Boolean,
				default: false,
			},
			backgroundColor: {
				type: String,
				default: "white",
			},
			valueWhenIsEmpty: {
				type: String,
				default: "", // "0" or "" or null
			},
			valueOptions: {
				type: Object,
				default: function () {
					return {
						min: 0,
						minEvent: "SetValueMin",
					};
				},
			},
			options: {
				type: Object,
				default: function () {
					return {
						locale: "en-US",
						prefix: "",
						suffix: "",
						length: 11,
						precision: 0,
					};
				},
			},
			// Other v-text-field properties
			properties: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		data: () => ({}),
		computed: {
			cmpValue: {
				get: function () {
					return this.value;
				},
				set: function (newValue) {
					this.$emit("input", newValue);
				},
			},
		},
		methods: {
			keyPress($event) {
				// console.log($event.keyCode); //keyCodes value
				let keyCode = $event.keyCode ? $event.keyCode : $event.which;
				// if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
				if (keyCode < 48 || keyCode > 57) {
					// 46 is dot
					$event.preventDefault();
				}
				if (this.targetLength()) {
					$event.preventDefault();
				}
			},
			// Retira todos os caracteres não numéricos e zeros à esquerda
			cleanNumber: function (value) {
				let result = "";
				if (value) {
					let flag = false;
					let arrayValue = value.toString().split("");
					for (var i = 0; i < arrayValue.length; i++) {
						if (this.isInteger(arrayValue[i])) {
							if (!flag) {
								// Retirar zeros à esquerda
								if (arrayValue[i] !== "0") {
									result = result + arrayValue[i];
									flag = true;
								}
							} else {
								result = result + arrayValue[i];
							}
						}
					}
				}
				return result;
			},
			isInteger(value) {
				let result = false;
				if (Number.isInteger(parseInt(value))) {
					result = true;
				}
				return result;
			},
			targetLength() {
				if (
					Number(this.cleanNumber(this.value).length) >=
					Number(this.options.length)
				) {
					return true;
				} else {
					return false;
				}
			},
			onBlur() {
				if (
					this.value.length === 0 ||
					parseFloat(this.value) <= this.valueOptions.min
				)
					this.$emit(
						this.valueOptions.minEvent || "SetValueMin",
						this.valueOptions.min
					);

				if (
					this.valueOptions.max &&
					parseFloat(this.value) >= this.valueOptions.max
				)
					this.$emit(
						this.valueOptions.maxEvent || "SetValueMax",
						this.valueOptions.max
					);
			},
		},
	};
</script>

<style >
	.type-v-text-currency .v-label {
		/* display: none; */
	}
</style>
