import http from "@/plugins/http";
import helpers from "./helpers";

export default {
  data() {
    return {
      globalDateFormate: "MM-DD-YYYY",
    };
  },
  mounted() {},
  computed: {
    baseUrl() {
      return helpers.baseUrl();
    },
    apiKey() {
      return "rocket-flood-1qaz2wsx3edec-0okmnj1";
    },
    sessionKey() {
      return this.$store.getters["auth/session_key"];
    },
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    currentUserRole() {
      return this.currentUser?.role;
    },
    currentUserAccesses() {
      return this.currentUser?.user_accesses?.payload;
    },
    previousUserData() {
      return this.$store.getters["auth/get_previous_user_data"];
    },
    previousUser() {
      return this.previousUserData?.user;
    },
    previousUserRole() {
      return this.previousUser?.role;
    },
    previousUserAccesses() {
      return this.previousUser?.user_accesses?.payload;
    },
  },
  methods: {
    selectedQuoteCarrierNameForUser(selectedQuote, currentUser = null) {
      if (!currentUser) {
        currentUser = this.currentUser;
      }
      let currentUserAccesses = currentUser?.user_accesses?.payload;

      if (currentUserAccesses?.display_carriers == "Yes") {
        return selectedQuote.payload.carrier_name;
      }
      return `Carrier${selectedQuote.id}`;
    },
    isSearchStringInData(search, data) {
      return search && data
        ? JSON.stringify(data)
            ?.toString()
            ?.toLowerCase()
            ?.includes(search?.toString()?.toLowerCase())
        : true;
    },
    refreshPage() {
      document.location.reload();
    },
    colSize(lgAndUp = 3, mdAndUp = 4, smAndUp = 6, smDown = 12) {
      let breakpoint = this.$vuetify.breakpoint;
      return breakpoint.lgAndUp
        ? lgAndUp
        : breakpoint.mdAndUp
        ? mdAndUp
        : breakpoint.smAndUp
        ? smAndUp
        : smDown;
    },
    showMessageFromResponseDta(responseDta) {
      this.$notify({
        group: "msg",
        title: responseDta?.error_obj
          ? responseDta?.message || "Error"
          : "Message",
        text: responseDta?.error_obj
          ? Object.values(responseDta?.error_obj)[0][0]
          : responseDta?.message || "Internet issue or contact IT.",
        type: responseDta?.status || "error",
      });
    },
    showNotification(text, type = "info", title = "Notification") {
      this.$notify({
        group: "msg",
        title: title,
        text: text,
        type: type,
      });
    },
    async post(url, data = {}, loading = false) {
      var $this = this;
      if (this.sessionKey) {
        data["session_key"] = this.sessionKey;
      }
      return await http
        .post(url, data, {
          headers: {
            apiKey: $this.apiKey,
          },
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          this.$notify({
            group: "msg",
            title: "Error Message",
            text: "Internet issue or contact it team.",
            type: "error",
          });
          return null;
        });
    },
    async get(url, headers = null, loading = false) {
      var $this = this;
      headers = {
        apiKey: $this.apiKey,
      };

      return await http
        .get(url, {
          headers,
          crossdomain: true,
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          this.$notify({
            group: "msg",
            title: "Error Message",
            text: "Internet issue or contact it team.",
            type: "error",
          });
          return null;
        });
    },
  },
};
