<template>
  <div style="max-width: 100%; overflow: auto">
    <contenteditable
      tag="div"
      id="textarea-html"
      v-model="content"
      v-bind="$attrs"
      :contenteditable="true"
      :noNL="false"
      :noHTML="false"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: ["value"],
  data() {
    return {
      content: null,
    };
  },
  mounted() {
    this.content = this.value;
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) this.content = newVal;
    },
    content(newVal, oldVal) {
      if (newVal != oldVal) this.$emit("input", newVal);
    },
  },
  methods: {},
};
</script>

<style>
#textarea-html {
  width: 100%;
  height: 250px;
  min-height: 200px;
}
#textarea-html img {
  width: 100%;
  height: auto;
}
</style>
