<template>
	<v-card>
		<v-card-title style="background-color: #80808029"> Login </v-card-title>
		<v-card-text>
			<v-form @submit.prevent v-model="isValidForm" ref="form">
				<FormMaker
					:col="{ cols: 12 }"
					:model="form_data"
					:schema="form_structure"
					:id="'login_form'"
				/>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn block color="secondary" @click="submit()"> Login </v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import FormMaker from "@/components/FormMaker";
	import { required, minLen } from "@/plugins/validationRules.js";
	import authStorage from "@/plugins/auth";

	export default {
		components: { FormMaker },
		props: { is_page: { required: true, default: true } },
		data: () => {
			return {
				isValidForm: false,
				form_data: {
					username: null,
					password: null,
				},
			};
		},
		computed: {
			form_structure() {
				return {
					username: {
						type: "text",
						label: "Username",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
						rules: [required("Username or Email is required."), minLen(3)],
					},
					password: {
						type: "password",
						label: "Password",
						clearable: true,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
						rules: [required("Password is required."), minLen(8)],
					},
				};
			},
		},
		watch: {},
		mounted() {},
		methods: {
			submit() {
				var $this = this;
				this.$refs.form.validate();
				if (!this.isValidForm) return;
				let data = this.form_data;
				this.post($this.baseUrl + "/login", data, true).then((data) => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						authStorage.unsetPreviousUserData();
						auth.login(
							data.data.session.session_key,
							data.data.user,
							data.data.user.role
						);
						$this.$emit("logged_in", data);
						if ($this.is_page) {
							$this.$router.replace({ name: "account" });
						}
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	#login_form .input-custom-title {
		display: none;
	}
</style>
