<template>
	<div id="agent-portal">
		<v-app-bar
			class="header dark-primary-bg elevation-0"
			height="120"
			absolute
			app
		>
			<router-link :to="{ name: 'dashboard-home' }">
				<div
					:class="
						['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'd-none' : ''
					"
					class="ml-12"
					style="max-width: 70px"
				>
					<v-img
						src="/img/favicon.png"
						style="width: 100%"
						class="img"
						contain
					></v-img>
				</div>
			</router-link>
			<v-spacer></v-spacer>
			<router-link
				:to="{
					name:
						currentUserRole == 'customer' ? 'dashboard-home' : 'agent-portal',
				}"
			>
				<div style="max-width: 300px">
					<v-img src="/img/logo2.png" style="width: 100%" contain></v-img>
					<div class="body-1 px-6 py-2 text-center">
						The #1 InsureTech Agency Group in the Country
					</div>
				</div>
			</router-link>
			<v-spacer></v-spacer>
			<div
				:class="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'd-none' : ''"
				class="mr-12"
				style="max-width: 70px"
			>
				<div style="width: 70px">&nbsp;</div>
			</div>
		</v-app-bar>
		<v-main class="mt-0 mb-10 light-light-bg">
			<v-container>
				<router-view></router-view>
			</v-container>
		</v-main>
		<v-footer absolute :padless="true" app>
			<v-container class="footer dark-primary-bg pa-10" fluid>
				<div class="d-flex justify-center">
					<div
						class="pb-2"
						style="
							width: 100%;
							max-width: 900px;
							border-bottom: 1px solid white;
						"
					>
						<div style="max-width: 400px">
							<img
								style="width: 100%"
								contain
								src="/img/logo2.png"
								alt="logo"
							/>
						</div>
					</div>
				</div>
				<div class="d-flex justify-center">
					<div class="mt-6" style="width: 100%; max-width: 900px">
						<div
							class="d-flex align-stretch"
							:class="
								['xs', 'sm'].includes($vuetify.breakpoint.name)
									? 'flex-column'
									: 'flex-row'
							"
						>
							<div style="min-width: 33%">
								<div class="body-1 font-weight-light text-capitalize pa-2">
									The #1 InsureTech Agency Group in the Country. Help your
									clients secure the most competitive flood Insurance
									Obtainable.
								</div>
							</div>
							<div style="min-width: 33%">
								<div
									class="body-1 font-weight-light text-uppercase pa-2"
									:class="
										!['xs', 'sm'].includes($vuetify.breakpoint.name)
											? 'px-10'
											: ''
									"
								>
									<div class="light-primary-color font-weight-bold py-1">
										Quick Links
									</div>
									<div class="pb-1">
										<a
											href="//quotes.rocketflood.com/"
											target="_blank"
											rel="noopener noreferrer"
											>Home</a
										>
									</div>
									<div class="pb-1">
										<a
											href="//quotes.rocketflood.com/about"
											target="_blank"
											rel="noopener noreferrer"
											>About</a
										>
									</div>
									<div class="pb-1">
										<a
											href="//quotes.rocketflood.com/"
											target="_blank"
											rel="noopener noreferrer"
											>Get A Quote</a
										>
									</div>
									<div class="pb-1">
										<a
											href="//quotes.rocketflood.com/about"
											target="_blank"
											rel="noopener noreferrer"
											>Contact</a
										>
									</div>
								</div>
							</div>
							<div style="min-width: 33%">
								<div class="body-1 font-weight-light text-capitalize pa-2">
									<div
										class="light-primary-color font-weight-bold py-1 text-uppercase"
									>
										Address
									</div>
									<div class="pb-1">
										2005 Vista Parkway, Suite 200 West Palm Beach, FL 33411
									</div>
								</div>
								<div class="body-1 font-weight-light text-uppercase pa-2">
									<div class="light-primary-color font-weight-bold py-1">
										Phone number
									</div>
									<div class="pb-1">
										<a href="tel:561-404-4498">561-404-4498</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</v-container>
			<v-container class="footer-secondary dark-secondary-bg" fluid>
				<v-row>
					<v-col>
						<div class="text-center body-3 font-weight-light text-uppercase">
							&copy; {{ new Date().getFullYear() }} ROCKETFLOOD.COM · All rights
							Reserved. · Privacy Policy
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>
	</div>
</template>

<script>
	export default {
		head: {
			title: function () {
				return {
					inner: "Agent Portal",
				};
			},
		},
		created() {},
	};
</script>

<style lang="scss">
	@import "src/assets/variables.scss";

	#agent-portal {
		a {
			text-decoration: none !important;
			color: white !important;
		}
		.light-light-color {
			color: $light-light-color !important;
		}
		.light-primary-color {
			color: $light-primary-color !important;
		}
		.dark-primary-color {
			color: $dark-primary-color !important;
		}
		.dark-secondary-color {
			color: $dark-secondary-color !important;
		}
		.light-light-bg {
			background-color: $light-light-color !important;
		}
		.light-primary-bg {
			background-color: $light-primary-color !important;
		}
		.dark-primary-bg {
			background-color: $dark-primary-color !important;
		}
		.dark-secondary-bg {
			background-color: $dark-secondary-color !important;
		}
		.header {
			color: white !important;
			border-bottom: 4px solid $light-primary-color;
		}
		.footer {
			color: white !important;
		}
		.footer-secondary {
			color: white !important;
		}
	}
</style>
